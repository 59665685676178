import { Injectable } from '@angular/core';
import { SessionData, SessionMenu, SessionResourceMap, SessionRole, SessionUser } from '../interfaces/session';
import { environment, publicServices } from '../../../environments/environment';
import { isAfter, parseISO } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private session: SessionData | null = null;
  private readonly storage: Storage = localStorage;
  
  constructor() { }
  
  get isAuthenticated(): boolean {
    return this.getSession() !== null;
  }

  protected getSession(): SessionData | null {
    try {
      if (!this.session) {
        const token: string = this.storage.getItem(environment.appId) as string;
        const session: SessionData = jwtDecode(token);
        this.session = session;
        return this.session
      }

      return this.session;
    } catch (error) {
      this.clear();
      return null;
    }
  }

  get user(): SessionUser | null {
    if (!this.isAuthenticated) return null;

    const sessionData: SessionData = this.getSession() as SessionData;

    return sessionData?.user || null;
  }

  get roles(): SessionRole[] | null {
    if (!this.isAuthenticated) return null;

    const sessionData: SessionData = this.getSession() as SessionData;

    return sessionData?.roles || null;
  }

  get menus(): SessionMenu[] | null {
    if (!this.isAuthenticated) return null;

    const sessionData: SessionData = this.getSession() as SessionData;

    return sessionData?.menus || null;
  }

  get services(): SessionResourceMap[] | null {
    if (!this.isAuthenticated) return null;

    const sessionData: SessionData = this.getSession() as SessionData;
    let services: SessionResourceMap[] = publicServices;

    if (sessionData && Array.isArray(sessionData.services)) {
      services = services.concat(sessionData.services);
    }

    return services;
  }

  get isSessionExpired(): boolean {
    const session: SessionData = this.session as SessionData;
    const now: Date = new Date();
    const expiredAt: Date = new Date(session.exp * 1000);
    const expired: boolean = isAfter(now, expiredAt);

    if (expired) {
      this.clear();
    }

    return expired;
  }
 
  setLocalStorage(token: string): void {
    if (typeof window !== 'undefined') {
      this.clear();
      window.localStorage.setItem(environment.appId, token);
    }
  }

  create(sessionData: SessionData): void {
    this.clear();
    this.storage.setItem(environment.appId, btoa(JSON.stringify(sessionData)));
  }

  clear(): void {
    this.session = null;
    this.storage.removeItem(environment.appId);
  }


}
