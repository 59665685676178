import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ec-alert',
  standalone: true,
  imports: [],
  templateUrl: './ec-alert.component.html',
  styleUrl: './ec-alert.component.css',
  host: { ngSkipHydration: 'true' },
})
export class EcAlertComponent implements AfterViewInit {
  @Input() color: 'info' | 'success' | 'warning' | 'danger' = 'info';
  @Input() title!: string;
  @Input() message!: string;
  @Input() closable: boolean = true;
  @Input() type: 'solid' | 'outline' = 'solid';
  @Output() onClose = new EventEmitter<boolean>();
  @Input() show: boolean = true;

  ngAfterViewInit(): void {}

  onCloseClick() {
    this.show = false;
    this.onClose.emit(true);
  }

  get alertStyle() {
    let alertClass = 'ec-alert ';
    const alertType = this.type;
    const alertColor = this.color;

    if (alertType === 'solid') {
      alertClass += 'ec-alert-';
    }

    if (alertType === 'outline') {
      alertClass += 'ec-alert-outline-';
    }

    return alertClass + alertColor;
  }
}
