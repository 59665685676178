import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { SessionService } from '../../shared/services/session.service';
import { SessionUser } from '../../shared/interfaces/session';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [BreadcrumbComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  sessionService = inject(SessionService);
  breadcrumbs =[
    {
      routerLink: '',
      title: 'admin',
      active: false,
    },
  ];
  
  user: SessionUser | null = null;
  ngOnInit(): void {
    this.user = this.sessionService.user;
    console.log('user', this.user);
    
  }
}
