<div class="wrapper-form-login z-0">
    <div class="background-login">
        &nbsp;
    </div>
    <div class="col-10 col-md-6 col-lg-4 d-flex justify-content-center align-items-center position-relative">
        &nbsp;
        <div class="position-absolute bg-white p-3 rounded-circle border shadow-lg z-3">
          <img src="assets/img/EnigmaLogo-simple.png" alt="logo-enigma-lab">
        </div>
    </div>
    <div class="col-10 col-md-6 col-lg-4 col-xxl-5 z-2">
        <form [formGroup]="formLogin" (ngSubmit)="submitForm()">
            <div class="bg-info form-login bg-white rounded-4 px-4 px-md-5 pt-3 pb-5 py-xxl-5 shadow-lg d-flex flex-column z-2">
                <div class="col-12 text-center pt-4">
                    <label class="fs-32 text-black fw-bolder">
                        Welcome
                    </label>
                </div>
                <div class="col-12 text-center">
                    <span class="text-muted fs-14">
                        Sign in if you have an account in here
                    </span>
                </div>
                <div class="col-12 my-3" ngSkipHydration>
                    <ec-alert
                      title="Login Failed"
                      type="outline"
                      [color]="isRequestError && isRequestError.statusCode === 500 ? 'danger' : 'warning'"
                      class="mb-3"
                      [show]="isRequestError && isRequestError.statusCode ? true : false"
                      [message]="
                        isRequestError && isRequestError.message
                          ? isRequestError.message
                          : '-'
                      "
                      (onClose)="onAlertClose($event)"
                    ></ec-alert>
                  </div>
                <div class="ec-col-12">
                    <label for="" class="ec-form-label fs-14 fw-bold">Email</label>
                    <div class="ec-input-group-rounded">
                        <span class="ec-input-group-text">
                            <i class="bi bi-envelope"></i>
                        </span>
                        <input 
                            type="text" 
                            class="ec-form-control-rounded" 
                            placeholder="Email"
                            formControlName="username"
                        >
                    </div>
                    <ec-input-error [control]="formLogin.get('username')"></ec-input-error> 
                </div>
                <div class="ec-col-12 mt-2">
                    <label for="" class="ec-form-label fs-14 fw-bold">Password</label>
                    <div class="ec-input-group-rounded position-relative">
                        <span class="ec-input-group-text">
                            <i class="bi bi-lock"></i>
                        </span>
                        <input 
                            [type]="getInputType()"
                            class="ec-form-control-rounded pe-5" 
                            placeholder="Password"
                            formControlName="password"
                        >
                        <div class="d-flex align-items-top h-100 position-absolute end-0 pe-3">
                            <button type="button" class="btn-ghost" (click)="toggleShowPassword()">
                              <i *ngIf="!showPassword" class="bi bi-eye-fill"></i>
                              <i *ngIf="showPassword" class="bi bi-eye-slash-fill"></i>
                            </button>
                          </div>
                    </div>
                    <ec-input-error [control]="formLogin.get('password')"></ec-input-error> 
                </div>
                <div class="ec-col-12 mt-3">
                    <button 
                        type="submit" 
                        class="w-100 cursor-pointer ec-btn ec-btn-secondary ec-btn-rounded d-flex justify-content-center text-center"
                        [disabled]="!formLogin.valid" 
                    >
                        Sign In
                    </button>
                </div>
            </div>
        </form>
    </div>
    <app-loading *ngIf="loading"></app-loading>
  </div>