import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ILogin } from '../interface/ILogin';
import { CommonModule } from '@angular/common';
import { AuthService } from '../service/auth.service';
import { ILoginResponse } from '../interface/ILoginResponse';
import { SessionService } from '../../shared/services/session.service';
import { map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { EcInputErrorComponent } from '../../shared/components/ec-input-error/ec-input-error.component';
import { ErrorMessage } from '../../shared/interfaces/general-response';
import { LoadingComponent } from '../../shared/components/loading/loading.component';
import { EcAlertComponent } from '../../shared/components/ec-alert/ec-alert.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterLink, 
    ReactiveFormsModule, 
    CommonModule,
    EcInputErrorComponent,
    LoadingComponent,
    EcAlertComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit {
  loading = false;
  formLogin!: FormGroup;
  isRequestError!: ErrorMessage;
  showPassword: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private readonly session: SessionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}
  // _______________________________________________________ onLoad
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formLogin = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [ Validators.required,Validators.minLength(8)]),
      captcha: new FormGroup({
        token: new FormControl(''),
        answer: new FormControl(''),
      }),
    });
  }

  // _______________________________________________________ onClick
  submitForm(): void {
    this.loading = true;
    const formValue: ILogin = this.formLogin.value;
    let captcha = {
      token: '',
      answer: '',
    };

    if (formValue.captcha) {
      captcha = {
        token: formValue.captcha.token,
        answer: formValue.captcha.answer,
      };
    }

    const payload: ILogin = {
      ...formValue,
      username: formValue.username,
      password: formValue.password,
      captcha: captcha,
    };

    this.authService.login2(payload).subscribe({
      next: (credential: ILoginResponse) => {
        this.handleLogin(credential.data);
      },
      error: (error) => this.handleError(error),
    });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  // _____________________________________________________ Support Function
  formValidation(property: string): AbstractControl {
    return this.formLogin.get(property) as FormGroup;
  }

  protected handleLogin(token: string): void {
    this.loading = false;
    this.session.setLocalStorage(token);

    this.activatedRoute.queryParams
      .pipe(map((params) => params['next'] || null))
      .subscribe((next: string = 'dashboard') => {
        this.router.navigateByUrl(next).finally();
    });
    this.loading = false;
  }
  
  protected handleError(error: HttpErrorResponse): void {
    this.isRequestError = {
      ...this.isRequestError,
      statusCode: error.error.status.code,
      message: error.error.status.description
    }
    
    this.loading = false;
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.resetIsRequestError();
  }

  resetIsRequestError() {
    this.isRequestError.message = '';
    this.isRequestError.statusCode = 0;
  }

}
