import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IInstance, IOperatingSystem, IVMSandbox } from './vm-sandbox.interface';
import { Response } from '../../shared/interfaces/response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VmSanboxService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  list(_params: any) {
    return this.http.get<Response<IVMSandbox[]>>(
      `${environment.API_URI}/sandboxes`,
      {
        params: { ..._params },
        withCredentials: true,
      }
    );
  }

  deleteVMSandbox(VMSandboxId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.API_URI}/sandboxes/${VMSandboxId}`,
      { withCredentials: true }
    );
  }

  postVMSandbox(VMSandbox: IVMSandbox) {
    return this.http.post<Response<any>>(
      `${environment.API_URI}/sandboxes`,
      VMSandbox,
      { withCredentials: true }
    );
  }

  putVMSandbox(VMSandbox: IVMSandbox) {
    return this.http.put<Response<any>>(
      `${environment.API_URI}/sandboxes`,
      VMSandbox,
      { withCredentials: true }
    );
  }

  getSingleVMSandbox(VMSandboxId: string): Observable<Response<IVMSandbox>> {
    return this.http.get<Response<IVMSandbox>>(
      `${environment.API_URI}/sandboxes/${VMSandboxId}`,
      { withCredentials: true }
    );
  }

  listInstances(_params: any) {
    return this.http.get<Response<IInstance[]>>(
      `${environment.API_URI}/instances`,
      {
        params: { ..._params },
        withCredentials: true,
      }
    );
  }

  listOperationSystem(_params: any) {
    return this.http.get<Response<IOperatingSystem[]>>(
      `${environment.API_URI}/operation-systems`,
      {
        params: { ..._params },
        withCredentials: true,
      }
    );
  }

}
