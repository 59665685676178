import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  afterNextRender,
  inject,
} from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { Breadcrumbs } from '../../../shared/interfaces/breadcrumbs';
import { Sidebar } from '../../../shared/interfaces/sidebar';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UserService } from '../user.service';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CaptchaViewerComponent } from '../../../shared/components/captcha-viewer/captcha-viewer.component';
import { UserPasswordUpdate, UserRegister } from '../user.interface';
import { EcInputErrorComponent } from '../../../shared/components/ec-input-error/ec-input-error.component';
import { EcAlertComponent } from '../../../shared/components/ec-alert/ec-alert.component';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';
import {
  EcToasterService,
  TOAST_COLOR,
} from '../../../shared/components/ec-toaster/ec-toaster.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CaptchaViewerComponent,
    EcInputErrorComponent,
    EcAlertComponent,
    NgSelectModule,
    LoadingComponent,
  ],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.css',
})
export class UserFormComponent implements OnInit {
  sidebarForm: Sidebar[] = [
    {
      key: 'userData',
      title: 'User Data',
      active: false,
    },
    {
      key: 'security',
      title: 'Security',
      active: false,
    },
  ];
  activeSidebar: Sidebar = this.sidebarForm[0];
  breadcrumbs: Breadcrumbs[] = [
    {
      routerLink: '',
      title: 'Dashboard',
      active: false,
    },
    {
      routerLink: '',
      title: 'User Management',
      active: true,
    },
  ];
  userService = inject(UserService);
  activeRoute = inject(ActivatedRoute);
  router = inject(Router);
  toaster = inject(EcToasterService);
  userStatuses: String[] = [];
  userRoles: String[] = [];
  userId!: string;
  userForm!: FormGroup;
  captchaSvg!: string;
  formState: 'CREATE' | 'UPDATE' = 'CREATE';
  submitError!: HttpErrorResponse | undefined;
  loading: boolean = false;
  submitLoading: boolean = false;
  passwordForm!: FormGroup;

  constructor() {
    afterNextRender(() => {});
  }

  ngOnInit(): void {
    this.getUserRoles();
    this.getUserStatus();
    this.initForm();
    // this.getCaptcha();
    this.watchUserId();
  }

  watchUserId(): void {
    const userId = this.activeRoute.snapshot.params['id'];
    if (userId) {
      this.loading = true;
      this.userId = userId;
      this.getUserDetail(userId);
      this.formState = 'UPDATE';
      // this.userForm.get('captchaToken')?.clearValidators();
      // this.userForm.get('captchaAnswer')?.clearValidators();
      this.userForm.updateValueAndValidity();
    }
  }

  initForm() {
    this.userForm = new FormGroup({
      fullName: new FormControl(null, Validators.required),
      birthdate: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^(?:\+62|62)?[\s-]?0?8[1-9]\d[\s-]?\d{4}[\s-]?\d{2,5}$/,
        ),
      ]),
      role: new FormControl(null, [Validators.required]),
      status: new FormControl(null, Validators.required),
      // captchaToken: new FormControl(null, Validators.required),
      // captchaAnswer: new FormControl(null, Validators.required),
    });

    this.passwordForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      newPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  getUserRoles(): void {
    this.loading = true;
    this.userService.getRoles().subscribe((response) => {
      this.userRoles = response.data;
      this.loading = false;
    });
  }

  getUserStatus(): void {
    this.loading = true;
    this.userService.getStatus().subscribe((response) => {
      this.userStatuses = response.data;
      this.loading = false;
    });
  }

  getCaptcha(): void {
    this.loading = true;
    this.userService.getCaptcha().subscribe((response) => {
      this.captchaSvg = response.data.image;
      this.userForm.patchValue({ captchaToken: response.data.token });
      this.loading = false;
    });
  }

  getUserDetail(userId: string): void {
    this.userService.getUser(userId).subscribe({
      next: (response) => {
        const { data } = response;
        this.userForm.patchValue({
          fullName: data.fullName,
          birthdate: data.birthdate,
          phone: data.phone,
          email: data.email,
          role: data.role,
          status: data.status,
        });
        this.passwordForm.patchValue({
          id: data.id,
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.warn('Error Load Data User', error);
        this.loading = false;
      },
    });
  }

  setToActive(index: number) {
    this.activeSidebar = this.sidebarForm[index];
  }

  submitUserForm(): void {
    this.userForm.markAllAsTouched();
    this.userForm.markAsDirty();
    this.userForm.markAsTouched();
    this.submitError = undefined;
    if (this.userForm.invalid) return;
    this.loading = true;
    this.submitLoading = true;
    let payload = this.userForm.value;
    payload = {
      ...payload,
      // captcha: { token: payload.captchaToken, answer: payload.captchaAnswer },
    };

    if (this.formState === 'CREATE') {
      this.createUser(payload);
    } else {
      this.updateUser(payload);
    }
  }

  updateUser(payload: UserRegister) {
    let toasterMessage = {
      title: 'Success',
      message: 'User sucessfuly updated.',
      color: TOAST_COLOR.success,
    };
    this.userService
      .putUser(payload)
      .pipe(
        finalize(() => {
          this.toaster.showToast(
            toasterMessage.title,
            toasterMessage.message,
            toasterMessage.color,
          );
          this.submitLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.backToList();
        },
        error: (error: HttpErrorResponse) => {
          console.warn('error', error);
          this.submitError = error;
          toasterMessage = {
            title: 'Error',
            message: 'Failed to update user.',
            color: TOAST_COLOR.warning,
          };
        },
      });
  }

  createUser(payload: UserRegister) {
    let toasterMessage = {
      title: 'Success',
      message: 'User sucessfuly created.',
      color: TOAST_COLOR.success,
    };
    this.userService
      .postUser(payload)
      .pipe(
        finalize(() => {
          this.toaster.showToast(
            toasterMessage.title,
            toasterMessage.message,
            toasterMessage.color,
          );
          this.submitLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.backToList();
        },
        error: (error: HttpErrorResponse) => {
          console.warn('error', error);
          this.submitError = error;
          toasterMessage = {
            title: 'Error',
            message: 'Failed to create user.',
            color: TOAST_COLOR.warning,
          };
          this.loading = false;
        },
      });
  }

  updatePasswordUser(newPasswordPayload: UserPasswordUpdate) {
    let toasterMessage = {
      title: 'Success',
      message: 'Password sucessfuly updated.',
      color: TOAST_COLOR.success,
    };
    this.userService
      .putUserPassword(newPasswordPayload)
      .pipe(
        finalize(() => {
          this.toaster.showToast(
            toasterMessage.title,
            toasterMessage.message,
            toasterMessage.color,
          );
          this.submitLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.backToList();
        },
        error: (error: HttpErrorResponse) => {
          console.warn('error', error);
          this.submitError = error;
          toasterMessage = {
            title: 'Error',
            message: 'Failed to update user password.',
            color: TOAST_COLOR.warning,
          };
          this.loading = false;
        },
      });
  }

  submitUserPassword() {
    this.passwordForm.markAllAsTouched();
    this.passwordForm.markAsDirty();
    this.passwordForm.markAsTouched();
    this.submitError = undefined;
    if (this.passwordForm.invalid) return;
    this.loading = true;
    this.updatePasswordUser(this.passwordForm.value);
  }

  onConfirmPasswordChange() {
    const { newPassword, confirmPassword } = this.passwordForm.value;
    if (newPassword !== confirmPassword) {
      this.passwordForm.get('confirmPassword')?.setErrors({
        passwordMissmatch: true,
      });
      this.passwordForm.updateValueAndValidity();
    }
  }

  refreshCaptcha() {
    this.getCaptcha();
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.submitError = undefined;
  }

  backToList(): void {
    this.router.navigate(['/users']);
  }
}
