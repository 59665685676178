import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  afterNextRender,
  inject,
} from '@angular/core';

@Component({
  selector: 'app-ec-dialogue',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ec-dialogue.component.html',
  styleUrl: './ec-dialogue.component.css',
  host: { ngSkipHydration: 'true' },
})
export class EcDialogueComponent implements OnInit {
  @Input() message: string = '';
  @Input() title!: string;
  @Input() showCancelButton: boolean = true;
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<boolean>();

  elementRef = inject(ElementRef);
  dialogue!: HTMLDialogElement;

  constructor() {
    afterNextRender(() => {
      this.dialogue =
        this.elementRef.nativeElement.querySelector('#ecDialogue');
    });
  }

  ngOnInit(): void {}

  openDialogue(): void {
    this.dialogue.showModal();
  }

  closeDialogue(): void {
    this.dialogue.close();
    this.onClose.emit(true);
  }

  confirmDialogue(): void {
    this.onConfirm.emit(true);
    this.dialogue.close();
  }
}
