import { Component, OnInit, TemplateRef, ViewChild, afterNextRender, inject } from '@angular/core';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { IQueryParams, ITableHeader, ITablePaging } from '../../../shared/interfaces/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { IVMSandbox } from '../vm-sandbox.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { EcToasterService } from '../../../shared/components/ec-toaster/ec-toaster.service';
import { VmSanboxService } from '../vm-sanbox.service';
import { EcDialogueComponent } from '../../../shared/components/ec-dialogue/ec-dialogue.component';
import { ItemOS } from '../../../shared/types/constants';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { ErrorMessage } from '../../../shared/interfaces/general-response';
import { EcAlertComponent } from '../../../shared/components/ec-alert/ec-alert.component';

@Component({
  selector: 'app-vm-sandbox-list',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgxDatatableModule,
    CommonModule,
    NgxPopperjsModule,
    RouterModule,
    EcDialogueComponent,
    ReactiveFormsModule,
    LoadingComponent,
    EcAlertComponent,
  ],
  templateUrl: './vm-sandbox-list.component.html',
  styleUrl: './vm-sandbox-list.component.css',
})
export class VmSandboxListComponent implements OnInit {

  activeRoute = inject(ActivatedRoute);
  router = inject(Router);
  toaster = inject(EcToasterService);
  VMSandboxService = inject(VmSanboxService);
  @ViewChild(EcDialogueComponent) ecDialogue!: EcDialogueComponent;
  
  @ViewChild('colStatus') colStatus: TemplateRef<any> | undefined;
  @ViewChild('colAction') colAction: TemplateRef<any> | undefined;
  popperPlacements = NgxPopperjsPlacements;
  popperTrigger = NgxPopperjsTriggers;

  rows: IVMSandbox[] = [];
  columns: ITableHeader[] = [];
  ColumnMode = ColumnMode;
  paging!: ITablePaging;
  loadingTable: boolean = true;
  loading: boolean = false;
  isRequestError!: ErrorMessage;
  queryParams!: IQueryParams | any;
  searchInput: FormControl = new FormControl('');
  tableSort: any[] = [];
  selectedVMSandbox!: IVMSandbox;
  ItemOS = ItemOS;

  breadcrumbs = [
    {
      routerLink: '',
      title: 'Dashboard',
      active: false,
    },
    {
      routerLink: '',
      title: 'VM Sandbox',
      active: true,
    },
  ];

  // _________________________________________________ onLoad Page
  
    constructor() {
      afterNextRender(() => {
        this.watchQueryParam();
        this.watchSearchField();
      });
  
      this.columns = [
        {
          name: 'No',
          prop: 'no',
          sortable: false,
          resizeable: false,
          width: 40,
          minWidth: 40
        },
        {
          name: 'Training Name',
          prop: 'name',
          sortable: true,
          resizeable: false,
          width: 250,
          minWidth: 200
        },
        {
          name: 'VM Amount',
          prop: 'vmAmount',
          sortable: true,
          resizeable: false,
          width: 200,
          minWidth: 150
        },
        {
          name: 'Start Date',
          prop: 'startDate',
          sortable: true,
          resizeable: false,
          width: 150,
          minWidth: 120
        },
        {
          name: 'End Date',
          prop: 'endDate',
          sortable: true,
          resizeable: false,
          width: 150,
          minWidth: 120
        },
        {
          name: 'Instance Type',
          prop: 'instanceType',
          sortable: true,
          resizeable: false,
          width: 250,
          minWidth: 200
        },
        {
          name: 'Operational System',
          prop: 'operationSystem',
          sortable: true,
          resizeable: false,
          width: 250,
          minWidth: 200
        },
        {
          name: 'Status',
          prop: 'status',
          sortable: true,
          resizeable: false,
          cellTemplate: this.colStatus,
          width: 150,
          minWidth: 120
        },
        {
          name: 'Action',
          prop: 'action',
          sortable: false,
          resizeable: false,
          cellTemplate: this.colAction,
          width: 60,
          minWidth: 60
        },
      ];

      this.initQueryParams();
      this.updatePaging();
    }
  
    ngOnInit(): void {
      this.getVMList();
    }
  
    watchQueryParam(): void {
      this.activeRoute.queryParamMap.subscribe((paramsMap: any) => {
        const { q, sort, order } = paramsMap.params;
        this.queryParams = paramsMap.params;
        if (q) {
          this.queryParams = Object.assign({ ...this.queryParams, q: q });
          this.searchInput.patchValue(q);
        }
        if (sort && order) {
          this.tableSort = [
            {
              prop: order,
              dir: sort,
            },
          ];
          this.queryParams = Object.assign({
            ...this.queryParams,
            order: order,
            dir: sort,
          });
        }
      });
    }
  
    watchSearchField() {
      this.searchInput.valueChanges
        .pipe(debounceTime(2000), distinctUntilChanged())
        .subscribe((keyword: string) => {
          this.queryParams = Object.assign({
            ...this.queryParams,
            q: keyword,
            page: 1,
          });
          this.navigate();
        });
    }

    getVMList(): void {
      this.loading = true;
      this.VMSandboxService.list(this.queryParams).subscribe({
        next: (response) => {
          let tempVMList: IVMSandbox[] = [];
          Array.from(response.data).forEach((VMList: IVMSandbox, index: number) => {
            let tempIndex = index;
            if (response.paging) {
              tempIndex = (response.paging?.page - 1) * response.paging?.rowsPerPage + index;
            }
            tempVMList.push(Object.assign(VMList, { no: tempIndex + 1 }));
          });
          
          this.rows = tempVMList;
          this.updatePaging(
            response.paging?.totalPages,
            response.paging?.page,
            response.paging?.totalRows,
            response.paging?.rowsPerPage
          );
          this.loadingTable = false;
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => this.handleError(error),
      })
    }

  // _________________________________________________ onClick Function
  onSort(event: any): void {
    this.queryParams = Object.assign({
      ...this.queryParams,
      order: event.column.prop,
      sort: event.newValue,
    });
    this.navigate();
  }

  onPaginate(event: any): void {
    const { offset } = event;
    this.queryParams = Object.assign({
      ...this.queryParams,
      page: offset + 1,
    });
    this.updatePaging(
      this.paging.size,
      offset + 1,
      this.paging.totalElements,
      this.paging.limit
    );
    this.navigate();
  }

  onActionClick(selectedRow: IVMSandbox) {
    this.selectedVMSandbox = selectedRow;
  }

  onEditClick() {
    if (!this.selectedVMSandbox) return;
    this.router.navigate(['/sandbox/vm-sandbox/', this.selectedVMSandbox.id]);
  }

  onDetailClick() {
    if (!this.selectedVMSandbox) return;
    this.router.navigate([`/sandbox/vm-sandbox/${this.selectedVMSandbox.id}/ip-addresses`]);
  }

  onDeleteClick() {
    if (!this.selectedVMSandbox) return;
    this.ecDialogue?.openDialogue();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (!confirmed) return;
    if (!this.selectedVMSandbox) return;
    this.loading = true;
    this.VMSandboxService.deleteVMSandbox(this.selectedVMSandbox.id).pipe(finalize(() => {
      this.navigate();
    })).subscribe({
      next: () => {
        this.resetIsRequestError();
        this.showToastr('Success', 'Data has been deleted', 'green');
      },
      error: (error: HttpErrorResponse) => this.handleError(error),
    });
  }
  

  // _________________________________________________ Support Function
  navigate() {
    const params = new URLSearchParams(this.queryParams).toString();
    this.router.navigateByUrl(`/sandbox/vm-sandbox?${params}`).then(() => {
      this.getVMList();
    });
  }

  updatePaging(
    size: number = 0,
    pageNumber: number = 0,
    totalElements: number = 0,
    limit: number = 0
  ) {
    this.paging = {
      size: size,
      pageNumber: pageNumber,
      totalElements: totalElements,
      limit: limit,
    };
  }

  initQueryParams(): void {
    this.queryParams = {
      q: '',
      page: 1,
      order: 'createdAt',
      sort: 'desc',
    };
    this.tableSort = [
      {
        dir: this.queryParams.sort,
        prop: this.queryParams.order,
      },
    ];
  }

  maskingOperationSystem(value: string) {
    const abc = this.ItemOS.find((key) => key.value === value);
    return abc ? abc.label : value;
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.resetIsRequestError();
  }

  resetIsRequestError() {
    this.isRequestError.message = '';
    this.isRequestError.statusCode = 0;
  }

  protected handleError(error: HttpErrorResponse): void {
    this.isRequestError = {
      ...this.isRequestError,
      statusCode: error.status,
      message: error.statusText
    }
    this.loading = false;
  }

  showToastr(title: string, message: string, color: string) {
    this.toaster.showToast(title, message, color);
  }
}
