import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[EcClipboard]',
  standalone: true,
})
export class EcClipboardDirective {
  @Input() textToCopy: string = '';

  constructor(private el: ElementRef) {}

  @HostListener('click')
  onClick(): void {
    const text = this.textToCopy || this.el.nativeElement.innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  }
}
