import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const TOAST_COLOR = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
};

export const TOAST_TYPE = {
  solid: 'solid',
  outline: 'outline',
};

const TOAST_DELAY = 5000;

@Injectable({
  providedIn: 'root',
})
export class EcToasterService {
  public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Default Toast Message'
  );
  public toastTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Attention'
  );
  public toastState$: BehaviorSubject<string> = new BehaviorSubject<string>(
    TOAST_COLOR.success
  );

  public toastType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    TOAST_TYPE.outline
  );

  public toastIcon$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'bi-info-circle'
  );

  constructor() {}

  async showToast(
    toastTitle: string,
    toastMsg: string,
    toastColor: string = TOAST_COLOR.info,
    toastType: string = TOAST_TYPE.solid,
    toastBootstrapIcon: string ='', /** bootstrap icon only */
    autoclose: boolean = true,
  ): Promise<void> {
    this.toastState$.next(toastColor);
    this.toastMessage$.next(toastMsg);
    this.toastTitle$.next(toastTitle);
    this.toastType$.next(toastType);
    this.toastIcon$.next(toastBootstrapIcon);
    this.showsToast$.next(true);

    if (autoclose) {
      await this.delay(TOAST_DELAY);
      this.dismissToast();
    }
  }

  dismissToast(): void {
    this.showsToast$.next(false);
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
