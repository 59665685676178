<div class="d-flex flex-column pt-5">
  <div class="col-12 mb-2">
    <div class="d-flex flex-column">
        <app-breadcrumb [forOf]="breadcrumbs" class='me-auto'></app-breadcrumb> 
    </div>
  </div>
  <div class="col-12 mb-3">
      <div class="d-flex flex-column flex-md-row justify-content-between">
          <h3 class="fs-32 text-white fw-bolder">VM Sandbox</h3>
          <div class="button-group-right align-self-md-center align-self-end">
              <div class="col text-center">
                  <i class="bi bi-bell"></i>
              </div>
              <div class="col box-avatar">
                  <div class="avatar">
                      <img src="./assets/img/sepuh.png" alt="avatar">
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="col-12 mb-3">
    <div class="ec-card ec-status-neutral">
      <div class="d-flex flex-column mx-2 mt-2">
        <div class="ec-card ec-status-neutral">
          <div class="ec-card-header ec-neutral-100 d-flex flex-column">
              <label class="fs-16 ec-primary">
                  Training Data
              </label>
              <label class="fs-24 ec-primary fw-bolder">
                  VM Sandbox
              </label>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between px-2 pt-2 mb-2">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div class="ec-input-group-boxed">
                      <span class="ec-input-group-text">
                          <i class="bi bi-search"></i>
                      </span>
                      <input
                        [formControl]="searchInput"
                        type="text"
                        class="ec-form-control-boxed"
                        placeholder="Search"
                      />
                  </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3 text-end">
                  <a
                    routerLink="form"
                    type="button"
                    class="ec-btn ec-btn-outline-primary w-auto text-center"
                  >
                    <i class="bi bi-plus-circle ms-1"></i>
                    Create VM Sandbox
                  </a>
              </div>
          </div>
          <hr class="mx-2" />
          <div class="row mt-4 px-4">
            <div class="col-12 mb-3" ngSkipHydration>
              <ec-alert
                title="Request Failed"
                type="outline"
                [color]="isRequestError && isRequestError.statusCode === 500 ? 'danger' : 'warning'"
                class="mb-3"
                [show]="isRequestError && isRequestError.statusCode ? true : false"
                [message]="
                  isRequestError && isRequestError.message
                    ? isRequestError.message
                    : '-'
                "
                (onClose)="onAlertClose($event)"
              ></ec-alert>
            </div>
          </div>
          <div class="row mt-3">
              <div class="col-12 w-100">
                  <ngx-datatable
                    class="bootstrap table-responsive table-ngx-responsive"
                    [rows]="rows"
                    [columns]="columns"
                    [columnMode]="ColumnMode.force"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [rowHeight]="55"
                    [scrollbarH]="true"
                    [externalPaging]="true"
                    [count]="paging.totalElements"
                    [offset]="paging.pageNumber - 1"
                    [limit]="paging.limit"
                    [loadingIndicator]="loadingTable"
                    (sort)="onSort($event)"
                    (page)="onPaginate($event)"
                  >
                  @for (column of columns; track $index){
                    @if (column.prop === 'status') {
                      <ngx-datatable-column 
                        [name]="column.name" 
                        [prop]="column.prop"
                      >
                        <ng-template
                          let-value="value"
                          let-row="row"
                          ngx-datatable-cell-template
                        >
                          <span
                            [ngClass]="{
                              'ec-success': value === 'ACTIVE',
                              'ec-danger': value === 'INACTIVE'
                            }"
                          >
                            • {{ value }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                    } @else if (column.prop === 'startDate' || column.prop === 'endDate') {
                        <ngx-datatable-column 
                          [name]="column.name" 
                          [prop]="column.prop"
                        >
                          <ng-template
                            let-value="value"
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                          {{ value | date: 'dd/MM/yyyy'}}
                          </ng-template>
                        </ngx-datatable-column>
                    } @else if (column.prop === 'operationSystem') {
                      <ngx-datatable-column 
                        [name]="column.name" 
                        [prop]="column.prop"
                        [width]="column.width ? column.width : 250"
                        [minWidth]="column.minWidth ? column.minWidth : 200"
                      >
                        <ng-template
                          let-value="value"
                          let-row="row"
                          ngx-datatable-cell-template
                        >
                        {{ maskingOperationSystem(value) }}
                        </ng-template>
                      </ngx-datatable-column>
                    } @else if (column.prop === 'no') {
                      <ngx-datatable-column 
                        [name]="column.name" 
                        [prop]="column.prop"
                        [width]="40"
                        [minWidth]="40"
                      >
                        <ng-template
                          let-value="value"
                          let-row="row"
                          ngx-datatable-cell-template
                        >
                        {{ value }}
                        </ng-template>
                      </ngx-datatable-column>
                    } @else if (column.prop === 'action') {
                        <ngx-datatable-column
                          [name]="column.name"
                          [prop]="column.prop"
                          [sortable]="false"
                        >
                          <ng-template
                            let-value="value"
                            let-row="row"
                            ngx-datatable-cell-template
                          >
                            <button
                              #btnOptions
                              (click)="onActionClick(row)"
                              [popper]="popper1Content"
                              [popperTrigger]="popperTrigger.click"
                              [popperShowOnStart]="false"
                              [popperHideOnScroll]="true"
                              [popperPlacement]="popperPlacements.TOPSTART"
                              [popperStyles]="{
                                border: 0,
                                padding: '3px',
                                'box-shadow': '0 0 0'
                              }"
                              popperHideOnClickOutside
                              type="button"
                              class="ms-2 ec-btn ec-btn-primary py-1 px-2 mx-2"
                            >
                              <i class="bi bi-three-dots-vertical m-1"></i>
                            </button>
                          </ng-template>
                        </ngx-datatable-column>
                    } @else {
                      <ngx-datatable-column
                        [name]="column.name"
                        [prop]="column.prop"
                        [sortable]="column.sortable"
                        [width]="column.width ? column.width : 250"
                        [minWidth]="column.minWidth ? column.minWidth : 200"
                      >
                      </ngx-datatable-column>
                    } 
                  }
                  </ngx-datatable>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-ec-dialogue
    #ecDialogue
    ngSkipHydration
    title="Delete Confirmation"
    [message]="
      selectedVMSandbox
        ? 'Are you sure you want to delete ' + selectedVMSandbox.name + ' ? This action cannot be undone.'
        : ''
    "
    (onConfirm)="onDeleteConfirm($event)"
  ></app-ec-dialogue>
  <app-loading *ngIf="loading"></app-loading>
</div>
  
<popper-content #popper1Content>
  <div class="row container-popper">
    <div class="col-12">
      <ul class="list-group">
        <li class="list-group-item popper cursor-pointer" (click)="this.onEditClick()">
          <span class="ec-info-600">
            <a (click)="this.onEditClick()">
              <i class="bi bi-pencil-square mx-1"></i> Edit
            </a>
          </span>
        </li>
        <li class="list-group-item popper cursor-pointer" (click)="this.onDetailClick()">
          <span class="ec-info-600">
            <a (click)="this.onDetailClick()"> <i class="bi bi-eye mx-1"></i> View IP </a>
          </span>
        </li>
        <li class="list-group-item popper cursor-pointer" (click)="this.onDeleteClick()">
          <span class="ec-info-600">
            <a (click)="this.onDeleteClick()"> <i class="bi bi-trash mx-1"></i> Delete </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</popper-content>
  