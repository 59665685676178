import { Component, OnInit, afterNextRender, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { VmSanboxService } from '../vm-sanbox.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ISandbox } from '../vm-sandbox.interface';
import { ITableHeader } from '../../../shared/interfaces/table';
import { EcClipboardDirective } from '../../../shared/directives/ec-clipboard/ec-clipboard.directive';
import {
  EcToasterService,
  TOAST_COLOR,
  TOAST_TYPE,
} from '../../../shared/components/ec-toaster/ec-toaster.service';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { EcAlertComponent } from '../../../shared/components/ec-alert/ec-alert.component';
import { ErrorMessage } from '../../../shared/interfaces/general-response';

@Component({
  selector: 'app-ip-address',
  standalone: true,
  imports: [
    NgxDatatableModule,
    BreadcrumbComponent,
    ReactiveFormsModule,
    CommonModule,
    EcClipboardDirective,
    LoadingComponent,
    EcAlertComponent
  ],
  templateUrl: './ip-address.component.html',
  styleUrl: './ip-address.component.css',
})
export class IpAddressComponent implements OnInit {
  columns: ITableHeader[] = [];
  loadingTable: boolean = false;
  loading: boolean = false;
  columnMode = ColumnMode;
  searchInput: FormControl = new FormControl('');
  activeRoute = inject(ActivatedRoute);
  vmService = inject(VmSanboxService);
  toaster = inject(EcToasterService);
  vmId!: string;
  sandboxes: ISandbox[] = [];
  isRequestError!: ErrorMessage;
  breadcrumbs = [
    {
      routerLink: '/',
      title: 'Dashboard',
      active: false,
    },
    {
      routerLink: '/sandbox/vm-sandbox',
      title: 'VM Sandbox',
      active: false,
    },
    {
      routerLink: '/sandbox/vm-sandbox',
      title: 'Create VM Sandbox Form',
      active: false,
    },
    {
      routerLink: '',
      title: 'IP Addresses',
      active: true,
    },
  ];

  constructor() {
    afterNextRender(() => {});
    this.columns = [
      {
        name: 'No',
        prop: 'no',
        sortable: false,
        resizeable: false,
        width: 40,
        minWidth: 40,
      },
      {
        name: 'Training Name',
        prop: 'name',
        sortable: true,
        resizeable: false,
        width: 250,
        minWidth: 200,
      },
      {
        name: 'IP Addresses',
        prop: 'ipAddress',
        sortable: true,
        resizeable: false,
        width: 200,
        minWidth: 150,
      },
      {
        name: 'Action',
        prop: 'action',
        sortable: false,
        resizeable: false,
        width: 60,
        minWidth: 60,
      },
    ];
  }

  ngOnInit(): void {
    this.watchVmId();
  }

  watchVmId(): void {
    const vmId = this.activeRoute.snapshot.params['id'];
    if (vmId) {
      this.vmId = vmId;
      this.getVmDetail();
    }
  }

  getVmDetail(): void {
    this.loading = true;
    this.vmService.getSingleVMSandbox(this.vmId).subscribe({
      next: (response) => {
        const { sandbox } = response.data;

        let tempIPList: ISandbox[] = [];
        sandbox?.forEach((sandbox: ISandbox, index) => {
          tempIPList.push(Object.assign(sandbox, { no: index + 1 }));
        });

        this.sandboxes = tempIPList || [];
        this.loading = false;
      },
      error: (error: HttpErrorResponse) =>  this.handleError(error),
    });
  }

  onSshClick(vmUrl: string): void {
    window.open(vmUrl, '_blank');
  }

  onCopyIp(): void {
    this.toaster.showToast(
      'Copy to clipboard',
      'Ip address successfully copied',
      TOAST_COLOR.info,
      TOAST_TYPE.outline,
      'bi-clipboard2-check'
    );
  }

  copyAllList() {
    this.loading = true;
    let listCopied: Array<any> = [];
    this.sandboxes.map((sandbox) => {
     const item = `${sandbox.name} - ${sandbox.consoleUrl}`;
      listCopied.push(item);
    });
    this.copyToClipboard(listCopied);
  }

  copyToClipboard(arrayOfSandbox: string[]) {
    const text = arrayOfSandbox.join('\n');
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    
    this.loading = false;
    this.onCopyIp();
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.resetIsRequestError();
  }

  resetIsRequestError() {
    this.isRequestError.message = '';
    this.isRequestError.statusCode = 0;
  }

  protected handleError(error: HttpErrorResponse): void {
    this.isRequestError = {
      ...this.isRequestError,
      statusCode: error.status,
      message: error.statusText
    }
    this.loading = false;
  }
}
