<div class="d-flex flex-column pt-5">
    <div class="col-12 mb-2">
        <div class="d-flex flex-column">
            <app-breadcrumb [forOf]="breadcrumbs" class='me-auto'></app-breadcrumb> 
        </div>
    </div>
    <div class="col-12 mb-1">
        <div class="d-flex flex-column flex-md-row justify-content-between">
            <h3 class="fs-32 text-white fw-bolder">Dashboard</h3>
            <div class="button-group-right align-self-md-center align-self-end">
                <div class="col text-center">
                    <i class="bi bi-bell"></i>
                </div>
                <div class="col box-avatar">
                    <div class="avatar">
                        <img src="./assets/img/sepuh.png" alt="avatar">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mb-3">
        <p class="fs-20 text-white text-truncate">Welcome, {{ user?.email || '-' }}!</p>
        <!-- <div class="ec-card ec-status-neutral">
            <div class="d-flex flex-column flex-md-row mx-2 mt-2">
                <div class="col me-0 me-md-1 mb-2 mb-md-0">
                    <div class="ec-card ec-status-neutral">
                        <div class="d-flex flex-row justify-content-center align-items-md-center px-2 pt-2">
                            <div class="col-4 col-lg-2 col-md-5">
                                <div class="dashboard-card-icon">
                                    <img src="./assets/img/sepuh.png" alt="training-data">
                                </div>
                            </div>
                            <div class="col ps-3 d-flex flex-column">
                                <label class="fs-16 ec-primary-300">
                                    Training Data
                                </label>
                                <label class="fs-24 fw-semibold">
                                    100
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col ms-0 ms-md-1 mb-2 mb-md-0">
                    <div class="ec-card ec-status-neutral">
                        <div class="d-flex flex-row justify-content-center align-items-md-center px-2 pt-2">
                            <div class="col-4 col-lg-2 col-md-5">
                                <div class="dashboard-card-icon">
                                    <img src="./assets/img/sepuh.png" alt="training-data">
                                </div>
                            </div>
                            <div class="col ps-3 d-flex flex-column">
                                <label class="fs-16 ec-primary-300">
                                    Total For VM Running
                                </label>
                                <label class="fs-24 fw-semibold">
                                    345
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column mx-2 mt-2">
                <div class="ec-card ec-status-neutral">
                    <div class="d-flex flex-column flex-md-row px-2 pt-2 mb-2">
                        <div class="col-12 col-md-9 mb-3 mb-md-0">
                            <label class="fs-20 ec-primary-500 fw-bolder">
                                Docker Training Data 
                            </label>
                        </div>
                        <div class="col-12 col-md-3 d-flex flex-row justify-content-end">
                            <button type="button" class="ec-btn ec-btn-outline-neutral py-1 px-3"> 
                                <i class="bi bi-filter me-1"></i> Filter 
                            </button>
                            <button type="button" class="ms-2 ec-btn ec-btn-outline-neutral py-1 px-1"> 
                                <i class="bi bi-three-dots-vertical"></i>
                            </button>
                        </div>
                    </div>
                    
                    <hr class="mx-2" />
                    <div class="table-responsive px-2">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">No</th>
                                <th scope="col">Training Name</th>
                                <th scope="col">VM Amount</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>1</th>
                                <td>Training Docker</td>
                                <td>1</td>
                                <td>11 Mei 2024</td>
                                <td>18 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                              <tr>
                                <th>2</th>
                                <td>Training Docker</td>
                                <td>3</td>
                                <td>14 Mei 2024</td>
                                <td>20 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                              <tr>
                                <th>3</th>
                                <td>Training Docker</td>
                                <td>8</td>
                                <td>11 Mei 2024</td>
                                <td>30 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="px-2 pt-3 mb-2">
                        <a routerLink="#" class="custom-link fs-16 ec-primary-500 fw-bolder">
                            See More
                        </a>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column mx-2 mt-2">
                <div class="ec-card ec-status-neutral">
                    <div class="d-flex flex-row px-2 pt-2 mb-2">
                        <label class="fs-20 ec-primary-500 fw-bolder">
                            Kubernetes Training Data
                        </label>
                        <button type="button" class="ms-auto ec-btn ec-btn-outline-neutral py-1 px-3"> 
                            <i class="bi bi-filter me-1"></i> Filter 
                        </button>
                        <button type="button" class="ms-2 ec-btn ec-btn-outline-neutral py-1 px-1"> 
                            <i class="bi bi-three-dots-vertical m-1"></i>
                        </button>
                    </div>
                    <hr class="mx-2" />
                    <div class="table-responsive px-2">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">No</th>
                                <th scope="col">Training Name</th>
                                <th scope="col">Node Pool Name</th>
                                <th scope="col">Node Amount</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>1</th>
                                <td>Training Docker</td>
                                <td>Training Docker</td>
                                <td>1</td>
                                <td>11 Mei 2024</td>
                                <td>18 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                              <tr>
                                <th>2</th>
                                <td>Training Docker</td>
                                <td>Training Docker</td>
                                <td>3</td>
                                <td>14 Mei 2024</td>
                                <td>20 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                              <tr>
                                <th>3</th>
                                <td>Training Docker</td>
                                <td>Training Docker</td>
                                <td>8</td>
                                <td>11 Mei 2024</td>
                                <td>30 Mei 2024</td>
                                <td>
                                    <button type="button" class="ms-2 ec-btn ec-btn-primary py-1 px-2"> 
                                        <i class="bi bi-three-dots-vertical m-1"></i>
                                    </button>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="px-2 pt-3 mb-2">
                        <a routerLink="#" class="custom-link fs-16 ec-primary-500 fw-bolder">
                            See More
                        </a>
                    </div>
                </div>
            </div>
            
        </div> -->
    </div>
</div>