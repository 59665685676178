import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Params } from '@angular/router';
import { Observable, Observer, map, switchMap } from 'rxjs';
import { environment, publicServices } from '../../../environments/environment';
import { SessionResourceMap } from '../interfaces/session';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private readonly http: HttpClient,
    private readonly session: SessionService
  ) {}

  protected getResourceUrl(code: string, method: string): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      let services: SessionResourceMap[] = publicServices;
      const sessionServices: SessionResourceMap[] = this.session.services as SessionResourceMap[];

      if (Array.isArray(sessionServices)) {
        services = services.concat(sessionServices);
      }

      const service: SessionResourceMap = services.find(s => s.code === code && s.method === method) as SessionResourceMap;

      if (!service) {
        observer.error(new Error(`Service ${code} is not valid.`));
      } else {
        observer.next(service.url);
      }

      observer.complete();
    });
  }

  protected get headers(): HttpHeaders {
    return new HttpHeaders({
      'lang': environment.defaultLanguage,
    });
  }

  protected rebuildUrl(url: string, pathParams?: Params): string {    
    if (pathParams) {
      for (const key of Object.keys(pathParams)) {
        if (url.indexOf(`:${key}`) > -1) {
          url = url.replace(`:${key}`, pathParams[key]);
        } else {
          url += `/${pathParams[key]}`;
        }
      }
    }

    url = `${environment.apiBaseUrl.replace(/\/+$/, '')}/${url}`;
    return url;
  }
  

  get(code: string, params?: Params, pathParams?: Params): Observable<any> {
    return this.getResourceUrl(code, 'GET')
      .pipe(
        map((url: string) => this.rebuildUrl(url, pathParams)),
        switchMap((url: string) => this.http.get(url, { params, headers: this.headers, withCredentials: true }))
      );
  }

  post(code: string, payload?: Params, pathParams?: Params, params?: Params): Observable<any> {
    return this.getResourceUrl(code, 'POST')
      .pipe(
        map((url: string) => this.rebuildUrl(url, pathParams)),
        switchMap((url: string) => this.http.post(url, payload, { params, headers: this.headers, withCredentials: true }))
      );
  }

  put(code: string, payload: Params, pathParams?: Params): Observable<any> {
    return this.getResourceUrl(code, 'PUT')
      .pipe(
        map((url: string) => this.rebuildUrl(url, pathParams)),
        switchMap((url: string) => this.http.put(url, payload, { headers: this.headers, withCredentials: true }))
      );
  }

  delete(code: string, params?: Params, pathParams?: Params): Observable<any> {
    return this.getResourceUrl(code, 'DELETE')
      .pipe(
        map((url: string) => this.rebuildUrl(url, pathParams)),
        switchMap((url: string) => this.http.delete(url, { params, headers: this.headers, withCredentials: true }))
      );
  }
}
