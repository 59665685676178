import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbModel } from './model/breadcrumb.model';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css'
})
export class BreadcrumbComponent implements OnInit {

  @Input() forOf!: BreadcrumbModel[];

	@Input() link1!: BreadcrumbModel;
	@Input() link2!: BreadcrumbModel;
	@Input() link3!: BreadcrumbModel;

  allLink: BreadcrumbModel[] = [];
  
  ngOnInit(): void {   
    if(this.forOf && this.forOf.length > 0) {
      this.allLink = this.forOf;
    } else {
      this.allLink = this.allLink.concat([
				{ ...this.link1 },
				{ ...this.link2 },
				{ ...this.link3 },
			]);
    }

    this.allLink = this.allLink.filter((data) => data.title)
  }
}
