import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-captcha-viewer',
  standalone: true,
  imports: [],
  templateUrl: './captcha-viewer.component.html',
  styleUrl: './captcha-viewer.component.css',
  host: { ngSkipHydration: 'true' },
})
export class CaptchaViewerComponent implements OnChanges {
  @Input() svg!: string;
  @Output() onCaptchaRefresh = new EventEmitter<boolean>();

  sanitizedSvg!: SafeHtml;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(this.svg);
    }
  }

  refreshCaptcha() {
    this.onCaptchaRefresh.emit(true);
  }
}
