export const environment = {
  DEBUG_SERVER: 'Development',
  ASSET_URI: 'https://apps.enigmacamp.com/cms',
  API_URI: '/api',
  appId: 'enigma-lab',
  defaultLanguage: 'id',
  apiBaseUrl: 'api/',
  appBaseUrl: 'https://labs.enigmacamp.com/',
};

export const publicServices = [
  {
    code: 'POST_LOGIN',
    url: 'login',
    method: 'POST',
  },
];
