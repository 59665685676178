<div [className]="alertStyle" role="alert" [hidden]="!show">
  <i class="bi bi-info-circle"></i>
  <div class="ec-alert-wrapper-message">
    @if(title) {
    <h6 class="ec-alert-title">{{ title }}</h6>
    }
    <p class="ec-alert-body">
      {{ message }}
    </p>
  </div>
  @if(closable) {
  <button
    type="button"
    class="ec-alert-btn-close"
    data-bs-dismiss="alert"
    aria-label="Close"
    (click)="onCloseClick()"
  >
    <i class="bi bi-x"></i>
  </button>
  }
</div>
