<div class="d-flex flex-column pt-5">
  <div class="col-12 mb-2">
    <div class="d-flex flex-column">
        <app-breadcrumb [forOf]="breadcrumbs" class='me-auto'></app-breadcrumb> 
    </div>
  </div>

  <div class="col-12 mb-3">
      <div class="d-flex flex-column flex-md-row justify-content-between">
          <h3 class="fs-32 text-white fw-bolder">Create VM Sandbox Forms</h3>
          <div class="button-group-right align-self-md-center align-self-end">
              <div class="col text-center">
                  <i class="bi bi-bell"></i>
              </div>
              <div class="col box-avatar">
                  <div class="avatar">
                      <img src="./assets/img/sepuh.png" alt="avatar">
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="col-12 mb-3 ps-2">
    <a [routerLink]="['/sandbox/vm-sandbox']" class="ec-neutral-50">
      <i class="bi bi-arrow-left"></i> Back
    </a>
  </div>

  <div class="col-12 mb-3">
    <div class="ec-card ec-status-neutral">
      <div class="d-flex flex-column mx-2 mt-2">
        <div class="ec-card ec-status-neutral">
          <div class="ec-card-header d-flex flex-column">
            <label class="fs-24 ec-primary fw-bolder">
              Create VM Sandbox Form
            </label>
            <label class="fs-16 ec-primary">
              Please complete all the required fields for the virtual machine below!
            </label>
            <!-- <hr class="mx-2" /> -->
          </div>
          <div class="ec-card-body px-2">
            <div class="d-flex flex-column flex-md-row">
              <div class="col-md-4 col-lg-3 col-12 mb-3 mb-md-0">
                <div class="sidebar-form ec-neutral-50 position-relative">
                  <div class="main" id="menu-form">
                    @for (item of sidebarForm; track $index) {
                      <div 
                        class="list-item-parent p-2"
                        [ngClass]="{ active: activeSidebar.key === item.key }"
                        (click)="setToActive($index)"
                      >
                        <a class="d-flex flex-row align-items-center w-100">
                            <span class="description">
                                {{ item.title }}
                            </span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-9 box-form">
                <div class="col-12 mb-3" ngSkipHydration>
                  <ec-alert
                    title="Request Failed"
                    type="outline"
                    [color]="isRequestError && isRequestError.statusCode === 500 ? 'danger' : 'warning'"
                    class="mb-3"
                    [show]="isRequestError && isRequestError.statusCode ? true : false"
                    [message]="
                      isRequestError && isRequestError.message
                        ? isRequestError.message
                        : '-'
                    "
                    (onClose)="onAlertClose($event)"
                  ></ec-alert>
                </div>
                @if (activeSidebar.key === 'traningData') {
                  <form [formGroup]="formVmSandbox" (ngSubmit)="submitForm()">
                    <div class="d-flex flex-column mb-3">
                      <div class="col-12 mb-3">
                        <label for="name" class="ec-form-label">
                          Training Name <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                            <span class="ec-input-group-text">
                              <i class="bi bi-bounding-box"></i>
                            </span>
                            <input 
                              type="text"
                              class="ec-form-control-boxed" 
                              placeholder="Fill training name"
                              formControlName="name"
                            >

                          </div>
                          <ec-input-error
                              [control]="formVmSandbox.get('name')"
                          ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="tags" class="ec-form-label">
                          Tags 
                        </label> 
                        <button 
                          type="button"
                          class="btn btn-link"
                          [popper]="popper1Content"
                          [popperTrigger]="popperTrigger.click"
                          [popperShowOnStart]="false"
                          [popperHideOnScroll]="true"
                          [popperPlacement]="popperPlacements.RIGHT"
                          [popperStyles]="{
                            'border': '0.8px' ,
                            'border-color': '#A4A4A4',
                            'padding': '0px',
                            'box-shadow': '0 0 0',
                            'background-color': '#ECEFF8',
                            'min-width': '472px'
                          }"
                          >
                          <i class="bi bi-info-circle ec-primary"></i>
                        </button>
                        <div class="ec-input-group-boxed">
                          <span class="ec-input-group-text">
                            <i class="bi bi-bounding-box"></i>
                          </span>
                          <ng-select
                            class="ec-form-control-boxed custom-input-multiple"
                            id="tags"
                            name="tags"
                            bindLabel="name"
                            placeholder="Fill Tags"
                            appendTo="body"
                            [items]="tags"
                            [addTag]="addTagFn" 
                            formControlName="tags"
                            [multiple]="true"
                          ></ng-select>
                        </div>
                        <ec-input-error
                          [control]="formVmSandbox.get('tags')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="operationSystem" class="ec-form-label">
                          Operating system <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                            <span class="ec-input-group-text">
                              <i class="bi bi-cpu"></i>
                            </span>
                            <ng-select
                              class="ec-form-control-boxed custom-input"
                              id="operationSystem"
                              name="operationSystem"
                              bindLabel="name"
                              bindValue="value"
                              placeholder="Select operating system"
                              appendTo="body"
                              [items]="ItemOS" 
                              formControlName="operationSystem"
                              [multiple]="false"
                            ></ng-select>
                        </div>
                        <ec-input-error
                          [control]="formVmSandbox.get('operationSystem')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="instanceType" class="ec-form-label">
                          Instance type <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                            <span class="ec-input-group-text">
                              <i class="bi bi-cpu"></i>
                            </span>
                            <ng-select
                              class="ec-form-control-boxed custom-input"
                              id="instanceType"
                              name="instanceType"
                              bindLabel="name"
                              bindValue="name"
                              placeholder="Select instance type"
                              appendTo="body"
                              [items]="instances" 
                              formControlName="instanceType"
                              [multiple]="false"
                            ></ng-select>
                          </div>
                          <ec-input-error
                            [control]="formVmSandbox.get('instanceType')"
                          ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="vmAmount" class="ec-form-label">
                          VM Amount <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                            <span class="ec-input-group-text">
                              <i class="bi bi-cpu"></i>
                            </span>
                            <input 
                              type="text"
                              class="ec-form-control-boxed" 
                              placeholder="Text input"
                              formControlName="vmAmount"
                              (keypress)="numberOnly($event)"
                            >

                          </div>
                          <ec-input-error
                            [control]="formVmSandbox.get('vmAmount')"
                          ></ec-input-error>
                      </div>

                      <div class="col-12 d-flex flex-column mb-3">
                        <div class="col-12 d-flex flex-column flex-md-row">
                          <div class="col-12 col-md-6 pe-md-1 pe-0 mb-2 mb-md-0">
                            <label for="startDate" class="ec-form-label">
                              Start Date <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="ec-input-group-boxed">
                              <input 
                                type="datetime-local"
                                class="ec-form-control-boxed" 
                                placeholder="Text input"
                                formControlName="startDate"
                              >
                            </div>
                            <ec-input-error
                              [control]="formVmSandbox.get('startDate')"
                            ></ec-input-error>
                          </div>
                          <div class="col-12 col-md-6 ps-md-1 ps-0">
                            <label for="endDate" class="ec-form-label">
                              End Date <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="ec-input-group-boxed">
                              <input 
                                type="datetime-local"
                                class="ec-form-control-boxed" 
                                placeholder="Text input"
                                formControlName="endDate"
                                [min]="minimumEndDate"
                                [max]="maximumEndDate"
                              >
  
                            </div>
                            <ec-input-error
                              [control]="formVmSandbox.get('endDate')"
                            ></ec-input-error>
                          </div>
                        </div>
                        <div class="col-12">
                          <span class="fs-14 text-muted">
                            Note : Maximum duration for Sandbox usage is 2 weeks
                          </span>
                        </div>
                      </div>
                      
                      <div class="col-12 mb-3 d-flex flex-row">
                        <i class="bi bi-plus-circle align-self-center me-2 cursor-pointer {{ isAdvanceOption ? 'd-none' : 'd-block' }}" (click)="toggleAdvanceOption()"></i>
                        <i class="bi bi-dash-circle align-self-center me-2 cursor-pointer {{ isAdvanceOption ? 'd-block' : 'd-none' }}" (click)="toggleAdvanceOption()"></i>
                        <label for="" class="ec-form-label fs-20 ec-primary fw-bold mb-0 cursor-pointer" (click)="toggleAdvanceOption()">Advance options</label>
                      </div>

                      <div ngSkipHydration class="mb-3 {{ isAdvanceOption ? 'show-advance-option' : 'hide-advance-option' }}">
                        <div class="col-12 mb-3">
                          <label for="storage" class="ec-form-label">
                            Storage
                          </label>
                          <div class="ec-input-group-boxed">
                              <span class="ec-input-group-text">
                                <i class="bi bi-database"></i>
                              </span>
                              <input 
                                type="text"
                                class="ec-form-control-boxed" 
                                placeholder="Fill storage"
                                formControlName="storage"
                                (keypress)="numberOnly($event)"
                              >
  
                            </div>
                            <small
                                *ngIf="formValidation('storage').invalid && (formValidation('storage').dirty || formValidation('storage').touched)"
                                class="form-text text-danger"
                                >
                                <span *ngIf="formValidation('storage').errors?.['required']">
                                  This field is required.
                                </span>
                            </small>
                        </div>
  
                        <div class="col-12 mb-3">
                          <label for="reason" class="ec-form-label">
                            Reason
                          </label>
                          <div class="ec-input-group-boxed">
                              <textarea 
                                class="ec-form-control-boxed" 
                                placeholder="Fill reason" 
                                rows="5"
                                formControlName="reason"  
                              >
                              </textarea>
                            </div>
                            <small
                                *ngIf="formValidation('reason').invalid && (formValidation('reason').dirty || formValidation('reason').touched)"
                                class="form-text text-danger"
                                >
                                <span *ngIf="formValidation('reason').errors?.['required']">
                                  This field is required.
                                </span>
                            </small>
                        </div>
                      </div>
                    
                      <div class="col-12 mb-3 text-end">
                        <button *ngIf="!id" type="submit" class="ec-btn ec-btn-primary py-1 px-3" [disabled]="!formVmSandbox.valid">
                          {{ formState === "CREATE" ? "Create" : "Update" }} VM Sandbox
                          <span
                            *ngIf="submitLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div> 
                    </div>
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="loading"></app-loading>
</div>

 
<popper-content #popper1Content>
  <div class="fake-arrow"></div>
  <p class="p-2">
    Helps manage and monitor network access policies effectively
  </p>
</popper-content>