import {
  Component,
  OnInit,
  ViewChild,
  afterNextRender,
  inject,
} from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  IQueryParams,
  ITableHeader,
  ITablePaging,
} from '../../../shared/interfaces/table';
import { CommonModule } from '@angular/common';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
} from 'ngx-popperjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { User } from '../user.interface';
import { UserService } from '../user.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { EcDialogueComponent } from '../../../shared/components/ec-dialogue/ec-dialogue.component';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EcToasterService,
  TOAST_COLOR,
  TOAST_TYPE,
} from '../../../shared/components/ec-toaster/ec-toaster.service';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgxDatatableModule,
    CommonModule,
    NgxPopperjsModule,
    RouterModule,
    ReactiveFormsModule,
    EcDialogueComponent,
    LoadingComponent,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.css',
})
export class UserListComponent implements OnInit {
  @ViewChild(EcDialogueComponent) ecDialogue!: EcDialogueComponent;
  userService = inject(UserService);
  activeRoute = inject(ActivatedRoute);
  router = inject(Router);
  toaster = inject(EcToasterService);
  searchInput: FormControl = new FormControl('');
  popperPlacements = NgxPopperjsPlacements;
  popperTrigger = NgxPopperjsTriggers;
  selectedUser!: User;
  paging!: ITablePaging;
  rows: User[] = [];
  columns!: ITableHeader[];
  queryParams!: IQueryParams | any;
  tableSort: any[] = [];
  loadingTable: boolean = true;
  loading: boolean = false;
  requestError!: HttpErrorResponse | undefined;

  constructor() {
    afterNextRender(() => {
      this.watchSearchField();
      this.watchQueryParam();
    });

    this.columns = [
      {
        name: 'No',
        prop: 'no',
        sortable: false,
        resizeable: false,
      },
      {
        name: 'Name',
        prop: 'fullName',
        sortable: true,
        resizeable: false,
      },
      {
        name: 'Phone Number',
        prop: 'phone',
        sortable: true,
        resizeable: false,
      },
      {
        name: 'Role',
        prop: 'role',
        sortable: true,
        resizeable: false,
      },
      {
        name: 'Status',
        prop: 'status',
        sortable: true,
        resizeable: false,
      },
      {
        name: 'Action',
        prop: 'action',
        sortable: false,
        resizeable: false,
      },
    ];

    this.initQueryParams();
    this.updatePaging();
  }

  navigate() {
    const params = new URLSearchParams(this.queryParams).toString();
    this.router.navigateByUrl(`/users?${params}`).then(() => {
      this.getUser();
    });
  }

  ngOnInit(): void {
    this.getUser();
  }

  initQueryParams(): void {
    this.queryParams = {
      q: '',
      page: 1,
      order: 'createdAt',
      sort: 'desc',
    };
    this.tableSort = [
      {
        dir: this.queryParams.sort,
        prop: this.queryParams.order,
      },
    ];
  }

  watchSearchField() {
    this.searchInput.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((keyword: string) => {
        this.queryParams = Object.assign({
          ...this.queryParams,
          q: keyword,
          page: 1,
        });
        this.navigate();
      });
  }

  watchQueryParam(): void {
    this.activeRoute.queryParamMap.subscribe((paramsMap: any) => {
      const { q, sort, order } = paramsMap.params;
      this.queryParams = paramsMap.params;
      if (q) {
        this.queryParams = Object.assign({ ...this.queryParams, q: q });
        this.searchInput.patchValue(q);
      }
      if (sort && order) {
        this.tableSort = [
          {
            prop: order,
            dir: sort,
          },
        ];
        this.queryParams = Object.assign({
          ...this.queryParams,
          order: order,
          dir: sort,
        });
      }
    });
  }

  getUser(): void {
    this.loading = true;
    this.userService.getUsers(this.queryParams).subscribe((response) => {
      let tempUsers: User[] = [];
      Array.from(response.data).forEach((user: User, index: number) => {
        let tempIndex = index;
        if (response.paging) {
          tempIndex = (response.paging?.page - 1) * response.paging?.rowsPerPage + index;
        }
        tempUsers.push(Object.assign(user, { no: tempIndex + 1 }));
      });
      this.rows = tempUsers;
      this.updatePaging(
        response.paging?.totalPages,
        response.paging?.page,
        response.paging?.totalRows,
        response.paging?.rowsPerPage,
      );
      this.loadingTable = false;
      this.loading = false;
    });
  }

  deleteUser(): void {}

  onSort(event: any): void {
    this.queryParams = Object.assign({
      ...this.queryParams,
      order: event.column.prop,
      sort: event.newValue,
    });
    this.navigate();
  }

  onPaginate(event: any): void {
    const { offset } = event;
    this.queryParams = Object.assign({
      ...this.queryParams,
      page: offset + 1,
    });
    this.updatePaging(
      this.paging.size,
      offset + 1,
      this.paging.totalElements,
      this.paging.limit,
    );
    this.navigate();
  }

  onActionClick(selectedRow: User) {
    this.selectedUser = selectedRow;
  }

  breadcrumbs = [
    {
      routerLink: '',
      title: 'Dashboard',
      active: false,
    },
    {
      routerLink: '',
      title: 'User Management',
      active: true,
    },
  ];

  setPage(pageInfo: any) {
    this.paging.pageNumber = pageInfo.offset;
  }

  updatePaging(
    size: number = 0,
    pageNumber: number = 0,
    totalElements: number = 0,
    limit: number = 0,
  ) {
    this.paging = {
      size: size,
      pageNumber: pageNumber,
      totalElements: totalElements,
      limit: limit,
    };
  }

  onEditClick() {
    if (!this.selectedUser) return;
    this.router.navigate(['/users', this.selectedUser.id]);
  }

  onDeleteClick() {
    if (!this.selectedUser) return;
    this.ecDialogue?.openDialogue();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (!confirmed) return;
    if (!this.selectedUser) return;
    this.loading = true;
    this.userService
      .deleteUser(this.selectedUser.id)
      .pipe(
        finalize(() => {
          this.navigate();
        }),
      )
      .subscribe({
        next: () => {
          this.toaster.showToast(
            'Success',
            'User account successfully deleted!',
            TOAST_COLOR.info,
            TOAST_TYPE.outline,
          );
          this.requestError = undefined;
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.warn('error', error);
          this.requestError = error;
          this.loading = false;
        },
      });
  }

  doToast() {
    this.toaster.showToast(
      'test title',
      'testing lorem panjang bgt ipsum gaskeun bang kuy skididiw',
      TOAST_COLOR.info,
      TOAST_TYPE.outline,
      'bi-exclamation-octagon',
    );
  }
}
