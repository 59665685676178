import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Response } from '../../shared/interfaces/response';
import { environment } from '../../../environments/environment';
import {
  User,
  UserCaptcha,
  UserPasswordUpdate,
  UserRegister,
} from './user.interface';
import { isPlatformServer } from '@angular/common';
import { tap } from 'rxjs/operators';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

const USER_DATA_KEY = makeStateKey<any>('USERLIST');
const ROLE_DATA_KEY = makeStateKey<any>('USERROLELIST');
const STATUS_DATA_KEY = makeStateKey<any>('USERSTATUSLIST');

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  getRoles() {
    if (this.transferState.hasKey(ROLE_DATA_KEY)) {
      const data = this.transferState.get(ROLE_DATA_KEY, null);
      this.transferState.remove(ROLE_DATA_KEY);
      return of(data);
    } else {
      return this.http
        .get<any>(`${environment.API_URI}/users/role/list`, {
          withCredentials: true,
        })
        .pipe(
          tap((data) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(ROLE_DATA_KEY, data);
            }
          }),
        );
    }
  }

  getStatus() {
    if (this.transferState.hasKey(STATUS_DATA_KEY)) {
      const data = this.transferState.get(STATUS_DATA_KEY, null);
      this.transferState.remove(STATUS_DATA_KEY);
      return of(data);
    } else {
      return this.http
        .get<any>(`${environment.API_URI}/users/status/list`, {
          withCredentials: true,
        })
        .pipe(
          tap((data) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(STATUS_DATA_KEY, data);
            }
          }),
        );
    }
  }

  getCaptcha() {
    return this.http.get<Response<UserCaptcha>>(
      `${environment.API_URI}/captcha`,
      { withCredentials: true },
    );
  }

  postUser(userData: UserRegister) {
    return this.http.post<Response<any>>(
      `${environment.API_URI}/users`,
      userData,
      { withCredentials: true },
    );
  }

  putUser(userData: UserRegister) {
    return this.http.put<Response<any>>(
      `${environment.API_URI}/users`,
      userData,
      { withCredentials: true },
    );
  }

  getUsers(_params: any) {
    return this.http.get<Response<User[]>>(`${environment.API_URI}/users`, {
      params: { ..._params },
      withCredentials: true,
    });
  }

  getUserState(_params: any): Observable<Response<User[]>> {
    if (this.transferState.hasKey(USER_DATA_KEY)) {
      const data = this.transferState.get(USER_DATA_KEY, null);
      this.transferState.remove(USER_DATA_KEY);
      return of(data);
    } else {
      return this.http
        .get<any>(`${environment.API_URI}/users`, {
          params: { ..._params },
          withCredentials: true,
        })
        .pipe(
          tap((data) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(USER_DATA_KEY, data);
            }
          }),
        );
    }
  }

  getUser(userId: string): Observable<Response<User>> {
    return this.http.get<Response<User>>(
      `${environment.API_URI}/users/${userId}`,
      { withCredentials: true },
    );
  }

  deleteUser(userId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.API_URI}/users/${userId}`,
      { withCredentials: true },
    );
  }

  putUserPassword(updatePasswordPayload: UserPasswordUpdate) {
    return this.http.put<Response<any>>(
      `${environment.API_URI}/users/password`,
      updatePasswordPayload,
      { withCredentials: true },
    );
  }
}
