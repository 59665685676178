<div class="d-flex flex-column pt-5">
  <div class="col-12 mb-2">
    <div class="d-flex flex-column">
      <app-breadcrumb [forOf]="breadcrumbs" class="me-auto"></app-breadcrumb>
    </div>
  </div>
  <div class="col-12 mb-3">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <h3 class="fs-32 text-white fw-bolder">IP Address</h3>
      <div class="button-group-right align-self-md-center align-self-end">
        <div class="col text-center">
          <i class="bi bi-bell"></i>
        </div>
        <div class="col box-avatar">
          <div class="avatar">
            <img src="./assets/img/sepuh.png" alt="avatar" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3">
    <div class="ec-card ec-status-neutral">
      <div class="d-flex flex-column mx-2 mt-2">
        <div class="ec-card ec-status-neutral">
          <div class="ec-card-header d-flex flex-column">
            <span class="fs-24 ec-primary fw-bolder"> IP Addresses </span>
            <span class="fs-16 ec-primary"> Your IP addresses below! </span>
          </div>
          <div class="row mt-4 px-4">
            <div class="col-12 mb-3" ngSkipHydration>
              <ec-alert
                title="Request Failed"
                type="outline"
                [color]="isRequestError && isRequestError.statusCode === 500 ? 'danger' : 'warning'"
                class="mb-3"
                [show]="isRequestError && isRequestError.statusCode ? true : false"
                [message]="
                  isRequestError && isRequestError.message
                    ? isRequestError.message
                    : '-'
                "
                (onClose)="onAlertClose($event)"
              ></ec-alert>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-end">
              <button
                [disabled]="sandboxes && sandboxes.length <= 0"
                (click)="copyAllList()"
                type="button"
                class="ec-btn ec-btn-primary py-1 px-1 mx-2"
              >
                <span class="mx-2">Copy All</span>
              </button>
            </div>
            <div class="col-12 px-4">
            <hr class="mx-2 mt-4 mb-0"/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 w-100">
              <ngx-datatable
                class="bootstrap table-responsive table-ngx-responsive"
                [rows]="sandboxes"
                [columns]="columns"
                [columnMode]="columnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="55"
                [scrollbarH]="true"
                [loadingIndicator]="loadingTable"
              >
                @for (column of columns; track $index){ @if (column.prop ===
                'status') {
                <ngx-datatable-column [name]="column.name" [prop]="column.prop">
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span
                      [ngClass]="{
                        'ec-success': value === 'ACTIVE',
                        'ec-danger': value === 'INACTIVE'
                      }"
                    >
                      • {{ value }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                } @else if (column.prop === 'no') {
                <ngx-datatable-column
                  [name]="column.name"
                  [prop]="column.prop"
                  [width]="40"
                  [minWidth]="40"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    {{ value }}
                  </ng-template>
                </ngx-datatable-column>
                } @else if (column.prop === 'ipAddress') {
                <ngx-datatable-column
                  [name]="column.name"
                  [prop]="column.prop"
                  [sortable]="true"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    @if (value) {
                    <span class="mx-2"> {{ value }} </span>
                    <span
                      class="ec-btn ec-btn-link-primary text mb-4 cursor-pointer"
                      EcClipboard
                      [textToCopy]="value"
                      (click)="onCopyIp()"
                    >
                      <i class="bi bi-copy"></i>
                    </span>
                    } @else {
                    <span class="mx-2"> Waiting for provisioning... </span>
                    }
                  </ng-template>
                </ngx-datatable-column>
                } @else if (column.prop === 'action') {
                <ngx-datatable-column
                  [name]="column.name"
                  [prop]="column.prop"
                  [sortable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <button
                      [disabled]="!row.consoleUrl"
                      (click)="onSshClick(row.consoleUrl)"
                      type="button"
                      class="ms-2 ec-btn ec-btn-secondary py-1 px-1 mx-2"
                    >
                      <span class="mx-2"> SSH </span>
                    </button>
                  </ng-template>
                </ngx-datatable-column>
                } @else {
                <ngx-datatable-column
                  [name]="column.name"
                  [prop]="column.prop"
                  [sortable]="column.sortable"
                  [width]="column.width ? column.width : 250"
                  [minWidth]="column.minWidth ? column.minWidth : 200"
                >
                </ngx-datatable-column>
                } }
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="loading"></app-loading>
</div>