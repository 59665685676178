<div class="wrapper-container bg-primary-800">
    <div class="sidebar ec-neutral-50 {{ toggle ? 'hide' : '' }} position-relative">
        <div id="menu-button" class="{{ toggle ? 'hide' : '' }}">
            <input type="checkbox" id="menu-checkbox" #menuCheckbox>
            <label for="menu-checkbox" id="menu-label" (click)="toggleMenu()">
                <div id="button-circle">
                    <i class="bi bi-chevron-left icon"></i>
                </div>
            </label>
        </div>
        <div class="header fs-14">
            <div class="col illustration">
                <img src="./assets/img/EnigmaLogo.png" alt="logo" class="logo">
            </div>
        </div>

        <div class="main mt-3" id="menu">
            <div class="list-item-parent"
                routerLink="dashboard"
                aria-current="page"
                routerLinkActive="active"
                #rlaDashboard="routerLinkActive"
            >
                <a routerLink="dashboard" class="collapsed d-flex flex-row align-items-center w-100">
                    <i class="bi bi-house-door icon"></i>
                    <span class="description">
                        Dashboard
                    </span>
                </a>
            </div>
            
            <div class="list-item-parent"
                routerLink="sandbox"
                aria-current="page"
                routerLinkActive="active"
                #rlaSandbox="routerLinkActive"
            >
                <a href="#" (click)="toggleSubMenu()" class="collapsed d-flex flex-row align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#sandbox" aria-expanded="false" aria-controls="sandbox">
                    <i class="bi bi-database icon"></i>
                    <span class="description">
                        Sandbox
                    </span>
                    <i class="bi bi-chevron-right ms-auto fs-12 icon"></i>
                </a>
            </div>
            <ul id="sandbox" class="sidebar-dropdown list-unstyled collapse ps-3" data-bs-parent="#menu">
                <li class="sidebar-item">
                    <div class="list-item-child"
                        routerLink="sandbox/vm-sandbox"
                        aria-current="page"
                        routerLinkActive="active"
                        #rlaVMSandbox="routerLinkActive"
                    >
                        <a routerLink="sandbox/vm-sandbox">
                            <span class="description">
                                VM Sandbox
                            </span>
                        </a>
                    </div>
                </li>
                <!-- <li class="sidebar-item">
                    <div class="list-item-child"
                        routerLink="sandbox/kubernetes-sandbox"
                        aria-current="page"
                        routerLinkActive="active"
                        #rlaKubernetesSandbox="routerLinkActive"
                    >
                        <a routerLink="sandbox/kubernetes-sandbox">
                            <span class="description">
                                Kubernetes Sanbox
                            </span>
                        </a>
                    </div>
                </li> -->
            </ul>

            <!-- <div class="list-item-parent"
                routerLink="networking"
                aria-current="page"
                routerLinkActive="active"
                #rlaNetworking="routerLinkActive"
            >
                <a href="#" (click)="toggleSubMenu()" class="collapsed d-flex flex-row align-items-center w-100" data-bs-toggle="collapse" data-bs-target="#networking" aria-expanded="false" aria-controls="networking">
                    <i class="bi bi-router icon"></i>
                    <span class="description">
                        Networking
                    </span>
                    <i class="bi bi-chevron-right ms-auto fs-12 icon"></i>
                </a>
            </div>
            <ul id="networking" class="sidebar-dropdown list-unstyled collapse ps-3 pt-2" data-bs-parent="#menu">
                <li class="sidebar-item">
                    <div class="list-item-child"
                        routerLink="networking/firewall"
                        aria-current="page"
                        routerLinkActive="active"
                        #rlaFirewall="routerLinkActive"
                    >
                        <a routerLink="master/account">
                            <span class="description">
                                Firewall
                            </span>
                        </a>
                    </div>
                </li>
            </ul> -->

            <div class="list-item-parent"
                routerLink="users"
                aria-current="page"
                routerLinkActive="active"
                #rlaUserManagement="routerLinkActive"
            >
                <a routerLink="users" class="collapsed d-flex flex-row align-items-center w-100">
                    <i class="bi bi-person icon"></i>
                    <span class="description">
                        User Management
                    </span>
                </a>
            </div>
        </div>

        <div class="footer mt-auto">
            <!-- <div class="list-item-parent"
                routerLink="setting"
                aria-current="page"
                routerLinkActive="active"
                #rlaSetting="routerLinkActive"
            >
                <a routerLink="setting" class="collapsed d-flex flex-row align-items-center w-100">
                    <i class="bi bi-gear icon"></i>
                    <span class="description">
                        Setting
                    </span>
                </a>
            </div> -->

            <div class="list-item-parent mt-auto"
                aria-current="page"
                routerLinkActive="active"
                #rlaLogout="routerLinkActive"
                (click)="onLogout()"
            >
                <a (click)="onLogout()" class="collapsed d-flex flex-row align-items-center w-100">
                    <i class="bi bi-box-arrow-left icon"></i>
                    <span class="description">
                        Logout
                    </span>
                </a>
                <label ngSkipHydration 
                    *ngIf="isRequestError && isRequestError.statusCode ? true : false" 
                    class="fs-14 {{isRequestError && isRequestError.statusCode === 500 ? 'text-danger' : 'text-warning'}}"
                >
                    Logout Failed: 
                    <span class="fs-16">
                        {{ 
                            isRequestError && isRequestError.message ? 
                            isRequestError.message : '-' 
                        }}
                    </span>
                </label>
            </div>
        </div>
    </div>
    <div class="main-content">
        <div class="static-content">
            &nbsp;
        </div>
        <div class="d-flex flex-column z-1 position-relative">
            <div class="col-11 mx-auto">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    
  <app-loading *ngIf="loading"></app-loading>
</div>