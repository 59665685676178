<dialog #ecDialogue id="ecDialogue">
  <div class="d-flex flex-column">
    <div class="col-12 col-md-6 col-lg-5 mx-auto position-relative z-0">
      <div class="container-absolute z-1 rounded-bottom-3">
        &nbsp;
      </div>
      <div class="wrapper-dialog ec-bg-primary z-0 rounded-3">
        <div class="col-12">
          @if(title) {
            <label class="fs-14 text-white px-3 pb-5">
              {{ title }}
            </label>
          }
        </div>
        <div class="col-6 mb-3 z-2" [ngClass]="{'mt-5': !title}">
          <img src="../../../../assets/img/pana.png" alt="image-delete" class="img-fluid">
        </div>
        <div class="col-12 text-center px-5 mb-4 z-2">
          <label class="fs-20 fw-normal">
            {{ message }}
          </label>
        </div>
        <div class="col-12 d-flex flex-row justify-content-center z-2">
          @if (showCancelButton) {
            <button
              class="ec-btn ec-btn-secondary ec-btn-rounded px-5 me-2"
              (click)="closeDialogue()"
            >
              Cancel
            </button>
            }
            <button
              (click)="confirmDialogue()"
              class="ec-btn ec-btn-primary ec-btn-rounded px-5"
            >
              Confirm
            </button>
        </div>
      </div>
    </div>
  </div>
</dialog>
