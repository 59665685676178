import { Inject, Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { ILogin } from '../interface/ILogin';
import { Observable, map, retry } from 'rxjs';
import { ILoginResponse } from '../interface/ILoginResponse';
import { LoginResponse } from '../../shared/interfaces/general-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpService,
    private httpClient: HttpClient
  ) {}

  login(payload: ILogin): Observable<ILoginResponse> {
    return this.http.post('POST_LOGIN', payload).pipe(
      retry(3),
      map((response: LoginResponse<ILoginResponse>) => response.data)
    );
  }

  login2(payload: ILogin) {
    return this.httpClient.post<any>(`${environment.API_URI}/login`, payload, {
      withCredentials: true,
    });
  }
  
  logout() {
    return this.httpClient.delete(`${environment.API_URI}/logout`).pipe(retry(3));
  }
}
