<div
  [@toastTrigger]="(toastService.showsToast$ | async) ? 'open' : 'close'"
  [class]="toastService.toastState$ | async"
  class="toaster"
>
  <div [class]="toastStyle" role="alert">
    @if (toastIcon != '') {
      <i [class]="toastIcon"></i>
    }
    <div class="ec-alert-wrapper-message">
      <h6 class="ec-alert-title">{{ toastService.toastTitle$ | async }}</h6>
      <p class="ec-alert-body mb-1">{{ toastService.toastMessage$ | async }}</p>
    </div>
    <button
      (click)="toastService.dismissToast()"
      type="button"
      class="ec-alert-btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    >
      <i class="bi bi-x"></i>
    </button>
  </div>
</div>
