import { Component, OnInit, afterNextRender, inject } from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Sidebar } from '../../../shared/interfaces/sidebar';
import { ItemOS } from '../../../shared/types/constants';
import { IInstance, IOperatingSystem, ITags, IVMSandbox } from '../vm-sandbox.interface';
import { VmSanboxService } from '../vm-sanbox.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { addDays, format } from 'date-fns';
import { IQueryParams } from '../../../shared/interfaces/table';
import {
  EcToasterService,
  TOAST_COLOR,
} from '../../../shared/components/ec-toaster/ec-toaster.service';
import { EcAlertComponent } from '../../../shared/components/ec-alert/ec-alert.component';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { EcInputErrorComponent } from '../../../shared/components/ec-input-error/ec-input-error.component';
import { ErrorMessage } from '../../../shared/interfaces/general-response';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
} from 'ngx-popperjs';

@Component({
  selector: 'app-vm-sandbox-form',
  standalone: true,
  templateUrl: './vm-sandbox-form.component.html',
  styleUrl: './vm-sandbox-form.component.css',
  host: { ngSkipHydration: 'true' },
  imports: [
    BreadcrumbComponent,
    CommonModule,
    NgxPopperjsModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    EcAlertComponent,
    LoadingComponent,
    EcInputErrorComponent,
  ],
})
export class VmSandboxFormComponent implements OnInit {
  vmSanboxService = inject(VmSanboxService);
  activeRoute = inject(ActivatedRoute);
  router = inject(Router);
  toaster = inject(EcToasterService);

  breadcrumbs = [
    {
      routerLink: '',
      title: 'Dashboard',
      active: false,
    },
    {
      routerLink: '/sandbox/vm-sandbox',
      title: 'VM Sandbox',
      active: false,
    },
    {
      routerLink: '',
      title: 'Create VM Sandbox Form',
      active: true,
    },
  ];

  sidebarForm: Sidebar[] = [
    {
      key: 'traningData',
      title: 'Training Data',
      active: true,
    },
  ];

  activeSidebar: Sidebar = this.sidebarForm[0];
  ItemOS: Array<IOperatingSystem> = [];
  formVmSandbox!: FormGroup;
  id!: string;
  isAdvanceOption: boolean = false;
  tags: Array<ITags> = [];
  minEndDate = new Date();
  maxEndDate = new Date(new Date().setDate(new Date().getDate() + 13));
  loading: boolean = false;
  submitLoading: boolean = false;
  submitError!: HttpErrorResponse | undefined;
  formState: 'CREATE' | 'UPDATE' = 'CREATE';
  vmSandboxId!: string;
  instances: Array<IInstance> = [];
  queryParams!: IQueryParams | any;
  isRequestError!: ErrorMessage;
  maximumEndDate!: string;
  minimumEndDate!: string;
  MAX_VM_RANGE_DAY: number = 12;
  popperPlacements = NgxPopperjsPlacements;
  popperTrigger = NgxPopperjsTriggers;
  temporaryVmAmount = 0;

  constructor() {
    afterNextRender(() => {});
  }

  // _____________________________________________________ Load Form
  ngOnInit(): void {
    this.buildForm();
    this.loadInstances();
    this.loadOS();
    this.watchUserId();
  }

  buildForm() {
    this.formVmSandbox = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.-]+$'),
      ]),
      tags: new FormControl(null),
      operationSystem: new FormControl(null, Validators.required),
      instanceType: new FormControl(null, Validators.required),
      vmAmount: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      storage: new FormControl(null),
      reason: new FormControl(null),
    });
    this.watchStartDate();
    this.watchVMAmount();
    this.formVmSandbox.get('endDate')?.disable();
  }

  watchUserId(): void {
    const vmSandboxId = this.activeRoute.snapshot.params['id'];
    if (vmSandboxId) {
      this.loading = true;
      this.vmSandboxId = vmSandboxId;
      this.getVMSandboxDetail(vmSandboxId);
      this.formState = 'UPDATE';
      this.formVmSandbox.updateValueAndValidity();
    }
  }

  watchVMAmount(): void {
    this.formVmSandbox.get('vmAmount')?.valueChanges.subscribe(
      (newVmAmount) => {
        if (newVmAmount < this.temporaryVmAmount) {
          this.formVmSandbox.get('vmAmount')?.setErrors({
            amountLowerThanBefore: true
          })
        } 
      }
    )
  }

  watchStartDate(): void {
    const _startDate = this.formVmSandbox.get('startDate');
    const _endDate = this.formVmSandbox.get('endDate');
    _startDate?.valueChanges.subscribe((latestStartDate: string) => {
      // when user click "clear" on date popup
      // value will be empty string ''
      if (latestStartDate != '') {
        this.maximumEndDate = format(
          addDays(latestStartDate, this.MAX_VM_RANGE_DAY),
          'yyyy-MM-dd HH:mm',
        );
        this.minimumEndDate = format(latestStartDate, 'yyyy-MM-dd HH:mm');
        _endDate?.enable();
      } else {
        _endDate?.patchValue('');
        _endDate?.disable();
      }
    });
  }

  getVMSandboxDetail(VMSandboxId: string): void {
    this.vmSanboxService.getSingleVMSandbox(VMSandboxId).subscribe({
      next: (response) => {
        const { data } = response;
        const maskingStartDate = format(data.startDate, 'yyyy-MM-dd HH:mm');
        const maskingEndDate = format(data.endDate, 'yyyy-MM-dd HH:mm');
        this.temporaryVmAmount = data.vmAmount;
        this.formVmSandbox.patchValue({
          name: data.name,
          operationSystem: data.operationSystem,
          instanceType: data.instanceType,
          vmAmount: data.vmAmount,
          startDate: maskingStartDate,
          endDate: maskingEndDate,
          storage: data.storage,
          reason: data.reason,
        });

        if (data.tags) {
          this.tags = JSON.parse(data.tags);
          this.formVmSandbox.controls['tags'].setValue(this.tags);
        }

        if (data.storage || data.reason) {
          this.toggleAdvanceOption();
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => this.handleError(error),
    });
  }


  loadOS() {
    this.loading = true;
    this.vmSanboxService.listOperationSystem(this.queryParams).subscribe({
      next: (response) => {
        this.ItemOS = response.data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => this.handleError(error),
    });
  }

  loadInstances() {
    this.loading = true;
    this.vmSanboxService.listInstances(this.queryParams).subscribe({
      next: (resIntsances) => {
        this.instances = resIntsances.data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => this.handleError(error),
    });
  }

  // _____________________________________________________ OnClick Function
  setToActive(index: number) {
    this.activeSidebar = this.sidebarForm[index];
  }

  toggleAdvanceOption() {
    this.isAdvanceOption = !this.isAdvanceOption;
  }

  addTagFn(tagName: string) {
    return { name: tagName, tag: true };
  }

  deleteData(tags: any) {
    const idx = this.tags.findIndex((data) => data.name === tags.name);
    this.tags.splice(idx, 1);
  }

  submitForm() {
    this.loading = true;
    this.submitLoading = true;
    this.submitError = undefined;
    const formValue: IVMSandbox = this.formVmSandbox.value;
    const storage = !formValue.storage
      ? 0
      : typeof formValue.storage === 'string'
        ? +formValue.storage
        : formValue.storage;
    const tags = !formValue.tags
      ? formValue.tags
      : typeof formValue.tags !== 'string'
        ? JSON.stringify(formValue.tags)
        : formValue.tags;
    const payload: IVMSandbox = {
      ...formValue,
      tags: tags,
      vmAmount:
        typeof formValue.vmAmount === 'string'
          ? +formValue.vmAmount
          : formValue.vmAmount,
      storage: storage,
    };

    if (this.formState === 'CREATE') {
      this.createVMSandbox(payload);
    } else {
      this.updateVMSandbox(payload);
    }
  }

  createVMSandbox(payload: IVMSandbox) {
    this.vmSanboxService.postVMSandbox(payload).subscribe({
      next: () => {
        this.showToastr(
          'Success',
          'VM Sandbox sucessfuly created',
          TOAST_COLOR.success,
        );
        this.submitLoading = false;
        this.loading = false;
        this.backToList();
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
        this.submitLoading = false;
      },
    });
  }

  updateVMSandbox(payload: IVMSandbox) {
    const payloadPut = {
      ...payload,
      id: this.vmSandboxId,
    };
    this.vmSanboxService.putVMSandbox(payloadPut).subscribe({
      next: () => {
        this.showToastr(
          'Success',
          'VM Sandbox sucessfuly Updated',
          TOAST_COLOR.success,
        );
        this.submitLoading = false;
        this.loading = false;
        this.backToList();
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
        this.submitLoading = false;
      },
    });
  }

  // _____________________________________________________ Support Function
  formValidation(property: string): AbstractControl {
    return this.formVmSandbox.get(property) as FormGroup;
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode === 47)) {
      return false;
    }
    return true;
  }

  backToList(): void {
    this.router.navigate(['/sandbox/vm-sandbox']);
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.resetIsRequestError();
  }

  resetIsRequestError() {
    this.isRequestError.message = '';
    this.isRequestError.statusCode = 0;
  }

  protected handleError(error: HttpErrorResponse): void {
    this.isRequestError = {
      ...this.isRequestError,
      statusCode: error.error.status.code,
      message: error.error.status.description,
    };

    const statusCode =
      this.isRequestError.statusCode === 500
        ? TOAST_COLOR.danger
        : TOAST_COLOR.warning;
    this.showToastr('Request Failed', this.isRequestError.message, statusCode);
    this.loading = false;
  }

  showToastr(title: string, message: string, color: string) {
    this.toaster.showToast(title, message, color);
  }
}
