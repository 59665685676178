import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

interface errorMessages {
  code: string;
  message: string;
  static: boolean;
}

@Component({
  selector: 'ec-input-error',
  standalone: true,
  imports: [],
  templateUrl: './ec-input-error.component.html',
  styleUrl: './ec-input-error.component.css',
  host: { ngSkipHydration: 'true' },
})
export class EcInputErrorComponent implements OnInit {
  @Input() control: AbstractControl | null = null;
  fieldArgumentValidations: any = {};
  errorMessages: errorMessages[] = [
    { code: 'default', message: 'Invalid field.', static: true },
    { code: 'required', message: 'This field is required.', static: true },
    { code: 'pattern', message: 'This field format is invalid.', static: true },
    {
      code: 'passwordMissmatch',
      message: 'Password and Confirm Password do not match.',
      static: true,
    },
    { code: 'email', message: 'This field must be an email.', static: true },
    {
      code: 'minlength',
      message: `This field must be minimum %requiredLength% characters`,
      static: false,
    },
    {
      code: 'amountLowerThanBefore',
      message: 'This field cannot be filled with lower value',
      static: true,
    },
  ];

  messages: string = '';

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.control?.statusChanges.pipe(distinctUntilChanged()).subscribe(() => {
      if (this.control?.pristine) return;
      const errors = this.control?.errors;
      if (!errors) {
        this.messages = '';
        return;
      }
      this.setErrorMessage(errors);
    });
  }

  setErrorMessage(controlErrors: ValidationErrors): void {
    let errorMessage!: errorMessages;
    // search error message from errorMessages[] based on controlErrors{}
    // if not found return the first array
    Object.keys(controlErrors).forEach((errCode) => {
      errorMessage =
        this.errorMessages.find((err) => err.code == errCode) ||
        this.errorMessages[0];
    });
    // if error message is not static
    // replace the template with %yourvalidationError%
    if (!errorMessage.static) {
      Object.keys(controlErrors).forEach((ctl) => {
        if (typeof controlErrors[ctl] == 'object') {
          for (let [key, value] of Object.entries(controlErrors[ctl])) {
            key = `%${key}%`;
            errorMessage.message = errorMessage.message.replace(
              new RegExp(key, 'g'),
              '' + value,
            );
          }
        }
      });
    }
    this.messages = errorMessage.message;
  }
}
