import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { RouteGuardService } from './shared/services/route-guard.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { UserFormComponent } from './pages/user/user-form/user-form.component';
import { VmSandboxListComponent } from './pages/vm-sandbox/list/vm-sandbox-list.component';
import { VmSandboxFormComponent } from './pages/vm-sandbox/form/vm-sandbox-form.component';
import { PagesComponent } from './pages/pages.component';
import { IpAddressComponent } from './pages/vm-sandbox/ip-address/ip-address.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
    ],
  },
  {
    path: '',
    component: PagesComponent,
    canActivateChild: [RouteGuardService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'setting', component: DashboardComponent },
      { path: 'users', component: UserListComponent },
      { path: 'users/form', component: UserFormComponent },
      { path: 'users/:id', component: UserFormComponent },
      { 
        path: 'sandbox',
        component: LayoutComponent,
        children: [
          { path: '', redirectTo: 'vm-sandbox', pathMatch: 'full' },
          { path: 'vm-sandbox', component: VmSandboxListComponent },
          { path: 'vm-sandbox/form', component: VmSandboxFormComponent },
          { path: 'vm-sandbox/:id', component: VmSandboxFormComponent },
          { path: 'vm-sandbox/:id/ip-addresses', component: IpAddressComponent },
        ]
      },
    ],
  },
];
