import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Renderer2, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';
import { SessionService } from '../shared/services/session.service';
import { environment as env } from '../../environments/environment';
import { EcAlertComponent } from '../shared/components/ec-alert/ec-alert.component';
import { ErrorMessage } from '../shared/interfaces/general-response';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingComponent } from '../shared/components/loading/loading.component';

@Component({
  selector: 'app-pages',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    RouterLink, 
    RouterLinkActive,
    EcAlertComponent,
    LoadingComponent
  ],
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.css'
})
export class PagesComponent {

  sessionService = inject(SessionService);
  @ViewChild('menuCheckbox') menuCheckbox!: ElementRef;
  toggle: boolean = false;
  isRequestError!: ErrorMessage;
  loading: boolean = false;

  constructor(
    private renderer: Renderer2,
    private readonly authService: AuthService,
    private readonly router: Router,

  ) { }

  toggleMenu() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.onClickRemoveShowClass();
    }
  }

  onClickRemoveShowClass() {
    const elements = document.querySelectorAll('.show');
    elements.forEach((element) => {
      this.renderer.removeClass(element, 'show');
    });
  }

  toggleSubMenu() {
    if (this.toggle) {
      this.toggleMenu();
      this.menuCheckbox.nativeElement.checked = !this.menuCheckbox.nativeElement.checked;
    }
  }

  onLogout() {
    this.loading = true;
    this.authService.logout().subscribe({
      next: () => {
        this.loading = false;
        this.sessionService.clear();
        this.router.navigate(['auth', 'login']).finally();
      },
      error: (err: HttpErrorResponse) => this.handleError(err),
    })
  }

  onAlertClose(closed: boolean): void {
    if (closed) this.resetIsRequestError();
  }

  resetIsRequestError() {
    this.isRequestError = {
      message: '',
      statusCode: 0,
    }
  }

  protected handleError(error: HttpErrorResponse): void {
    this.isRequestError = {
      ...this.isRequestError,
      statusCode: error.status,
      message: error.statusText
    }
    this.loading = false;

    setInterval(() => {
      this.resetIsRequestError()
    }, 10000);
    
  }
  
}
