import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Params, Router, RouterStateSnapshot } from '@angular/router';
import { SessionMenu } from '../interfaces/session';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate, CanActivateChild {

  constructor(
    private readonly session: SessionService,
    private readonly router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authorize(state);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authorize(state);
  }

  protected authorize(state: RouterStateSnapshot): boolean {
    const params: Params = { next: state.url };
    if (!this.session.isAuthenticated) {
      this.redirectToLogin(params);
    } else if (this.session.isAuthenticated && this.session.isSessionExpired) {
      this.redirectToLogin({ ...params, expired: 1 });
    }

    return true;
  }

  protected redirectToLogin(queryParams?: Params): void {
    this.router.navigate(['auth', 'login'], { queryParams })
      .finally();
  }

}
