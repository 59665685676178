<div class="d-flex flex-column pt-5">
  <div class="col-12 mb-2">
    <div class="d-flex flex-column">
      <app-breadcrumb [forOf]="breadcrumbs" class="me-auto"></app-breadcrumb>
    </div>
  </div>

  <div class="col-12 mb-3">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <h3 class="fs-32 text-white fw-bolder">
        {{ formState === "CREATE" ? "Create" : "Update" }} User
      </h3>
      <div class="button-group-right align-self-md-center align-self-end">
        <div class="col text-center">
          <i class="bi bi-bell"></i>
        </div>
        <div class="col box-avatar">
          <div class="avatar">
            <img src="./assets/img/sepuh.png" alt="avatar" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3 ps-2">
    <a [routerLink]="['/users']" class="ec-neutral-50">
      <i class="bi bi-arrow-left"></i> Back
    </a>
  </div>

  <div class="col-12 mb-3">
    <div class="ec-card ec-status-neutral">
      <div class="d-flex flex-column mx-2 mt-2">
        <div class="ec-card ec-status-neutral">
          <div class="ec-card-header d-flex flex-column">
            <label class="fs-24 ec-primary fw-bolder">
              {{ formState === "CREATE" ? "Create" : "Update" }} User
            </label>
            <label class="fs-16 ec-primary">
              Please complete all the required fields for the user.
            </label>
          </div>
          <div class="ec-card-body px-2">
            <div class="d-flex flex-column flex-md-row">
              <div class="col-md-4 col-lg-3 col-12 mb-3 mb-md-0">
                <div class="sidebar-form ec-neutral-50 position-relative">
                  <div class="main" id="menu-form">
                    @for (item of sidebarForm; track $index) {
                      <div
                        class="list-item-parent p-2"
                        [ngClass]="{ active: activeSidebar.key === item.key }"
                        (click)="setToActive($index)"
                        [hidden]="
                          formState === 'CREATE' && item.key === 'security'
                        "
                        ngSkipHydration
                      >
                        <a class="d-flex flex-row align-items-center w-100">
                          <span class="description">
                            {{ item.title }}
                          </span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-9 box-form">
                <div class="col-12" ngSkipHydration>
                  <ec-alert
                    title="Error!"
                    type="outline"
                    color="danger"
                    class="mb-3"
                    [show]="submitError != undefined ? true : false"
                    [message]="
                      submitError != undefined
                        ? submitError.error.status?.description
                        : '-'
                    "
                    (onClose)="onAlertClose($event)"
                  ></ec-alert>
                </div>

                @if (activeSidebar.key === "userData") {
                  <form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
                    <div class="d-flex flex-column mb-3">
                      <div class="col-12 mb-3">
                        <label for="fullName" class="ec-form-label">
                          Name <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <span class="ec-input-group-text">
                            <i class="bi bi-person-circle"></i>
                          </span>
                          <input
                            formControlName="fullName"
                            type="text"
                            class="ec-form-control-boxed"
                            placeholder="Fill name"
                          />
                        </div>
                        <ec-input-error
                          [control]="userForm.get('fullName')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="birthdate" class="ec-form-label">
                          Birthday <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <span class="ec-input-group-text">
                            <i class="bi bi-person-circle"></i>
                          </span>
                          <input
                            formControlName="birthdate"
                            type="date"
                            class="ec-form-control-boxed"
                            placeholder="Fill name"
                          />
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="email" class="ec-form-label">
                          Email <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <span class="ec-input-group-text">
                            <i class="bi bi-envelope"></i>
                          </span>
                          <input
                            formControlName="email"
                            type="email"
                            class="ec-form-control-boxed"
                            placeholder="Fill email"
                          />
                        </div>
                        <ec-input-error
                          [control]="userForm.get('email')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="phone" class="ec-form-label">
                          Phone number <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <span class="ec-input-group-text">
                            <i class="bi bi-telephone"></i>
                          </span>
                          <input
                            formControlName="phone"
                            type="phone"
                            class="ec-form-control-boxed"
                            placeholder="Fill phone number"
                          />
                        </div>
                        <ec-input-error
                          [control]="userForm.get('phone')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="role" class="ec-form-label">
                          Role <span class="text-danger">*</span>
                        </label>
                        <ng-select
                          class="ec-form-control-boxed custom-input"
                          id="role"
                          name="role"
                          bindLabel="name"
                          placeholder="Select role"
                          appendTo="body"
                          [items]="userRoles"
                          formControlName="role"
                          [multiple]="false"
                        ></ng-select>
                        <ec-input-error
                          [control]="userForm.get('role')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="status" class="ec-form-label">
                          Status <span class="text-danger">*</span>
                        </label>
                        <ng-select
                          class="ec-form-control-boxed custom-input"
                          id="status"
                          name="status"
                          bindLabel="name"
                          placeholder="Select status"
                          appendTo="body"
                          [items]="userStatuses"
                          formControlName="status"
                          [multiple]="false"
                        ></ng-select>
                        <ec-input-error
                          [control]="userForm.get('status')"
                        ></ec-input-error>
                      </div>

                      <!-- @if (formState === "CREATE") {
                        <div class="col-12 mb-3">
                          <label for="captchaAnswer" class="ec-form-label">
                            Captcha <span class="text-danger">*</span>
                          </label>
                          <app-captcha-viewer
                            [svg]="captchaSvg"
                            (onCaptchaRefresh)="refreshCaptcha()"
                          ></app-captcha-viewer>
                          <input
                            class="ec-form-control-boxed"
                            placeholder="Enter captcha code"
                            formControlName="captchaAnswer"
                          />
                          <ec-input-error
                            [control]="userForm.get('captchaAnswer')"
                          ></ec-input-error>
                        </div>
                      } -->

                      <div class="col-12 mb-3 text-end">
                        <button
                          class="ec-btn ec-btn-primary mb-2 me-2 float-end"
                        >
                          {{ formState === "CREATE" ? "Create" : "Update" }}
                          User
                          <span
                            *ngIf="submitLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </form>
                }

                @if (activeSidebar.key === "security") {
                  <form id="user-security" [formGroup]="passwordForm">
                    <div class="d-flex flex-column mb-3">
                      <div class="col-12 mb-3">
                        <label for="password" class="ec-form-label">
                          Password <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <input
                            type="password"
                            class="ec-form-control-boxed"
                            placeholder="New Password"
                            formControlName="newPassword"
                          />
                        </div>
                        <ec-input-error
                          [control]="passwordForm.get('newPassword')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="re-password" class="ec-form-label">
                          Re-type Password <span class="text-danger">*</span>
                        </label>
                        <div class="ec-input-group-boxed">
                          <input
                            type="text"
                            class="ec-form-control-boxed"
                            placeholder="Re-type Password"
                            type="password"
                            class="ec-form-control-boxed"
                            placeholder="Re-type Password"
                            formControlName="confirmPassword"
                            (change)="onConfirmPasswordChange()"
                          />
                        </div>
                        <ec-input-error
                          [control]="passwordForm.get('confirmPassword')"
                        ></ec-input-error>
                      </div>

                      <div class="col-12 mb-3 text-end">
                        <button
                          class="ec-btn ec-btn-primary mb-2 me-2 float-end"
                          (click)="submitUserPassword()"
                        >
                          Change password
                          <span
                            *ngIf="submitLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="loading"></app-loading>
</div>
