import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { SessionService } from '../services/session.service';
import { environment as env } from '../../../environments/environment';
import { HttpService } from '../services/http.service';

export const requestInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionService = inject(SessionService);

  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 401:
            console.error('Session Expired:', err);
            sessionService.clear();
            window.location.href = `${env.apiBaseUrl}?message=sessionExpired`
            break;

          case 403:
            console.error('Unauthorized Request:', err);
            break;

          default:
            console.error('Service Not Available:', err);
            break;
        }
      } else {
          // Handle non-HTTP errors
          console.error('Something Went Wrong:', err);
      }
      return throwError(() => err); 
    })
  );
};
