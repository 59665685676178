import { Component, OnInit, inject } from '@angular/core';
import { EcToasterService } from './ec-toaster.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ec-toaster',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ec-toaster.component.html',
  styleUrl: './ec-toaster.component.css',
  animations: [
    trigger('toastTrigger', [
      // This refers to the @trigger we created in the template
      state('open', style({ transform: 'translateY(0%)' })), // This is how the 'open' state is styled
      state('close', style({ transform: 'translateY(-200%)' })), // This is how the 'close' state is styled
      transition('open <=> close', [
        // This is how they're expected to transition from one to the other
        animate('300ms ease-in-out'),
      ]),
    ]),
  ],
  host: { ngSkipHydration: 'true' },
})
export class EcToasterComponent implements OnInit {
  toastService = inject(EcToasterService);

  ngOnInit(): void {}

  dismiss(): void {
    this.toastService.dismissToast();
  }

  get toastStyle() {
    let alertClass = 'ec-alert ';
    const alertType = this.toastService.toastType$.getValue();
    const alertColor = this.toastService.toastState$.getValue();

    if (alertType === 'solid') {
      alertClass += 'ec-alert-';
    }

    if (alertType === 'outline') {
      alertClass += 'ec-alert-outline-';
    }
    return alertClass + alertColor;
  }

  get toastIcon() {
    return this.toastService.toastIcon$.getValue();
  }

}
