<nav aria-label="breadcrumb">
    <ol class="breadcrumb rounded-pill border-2 border-breadcrumb px-3 pb-1">
        @for (link of allLink; track $index) {

            <li class="breadcrumb-item {{ link.active ? 'active' : '' }}">
                @if (!link.active) {
                    <a routerLink="{{ link.routerLink }}" class="text-white ec-fs-6">{{ link.title }}</a>
                } @else {
                    <a class="text-white ec-fs-5 fw-bold">{{ link.active ? link.title : '' }}</a>
                }
            </li>
        }
    </ol>
  </nav>
    